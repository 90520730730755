<i18n>
{
  "en": {
    "loading": "Loading...",
    "code_copied": "Copied coupon code to clipboard"
  },
  "ja": {
    "loading": "お待ちください 。。。",
    "code_copied": "クーポンコードをコピーしました"
  }
}
</i18n>

<template>
<div>
  <div v-for="(groupName, idx) in orderedGroupNames" :key="idx">
    <!-- carousel -->
    <div v-if="badminBannerGroups[groupName][0].banner_type == 'carousel'" class="container">
      <div class="banner">
        <vueper-slides lazy
          class="no-shadow"
          :slideRatio="320/800"
          :autoplay="true"
          :pause-on-hover="pauseOnHover"
          :speed="4500"
          :dragging-distance="70" prevent-y-scroll
          :arrowsOutside="false"
          :bullets-outside="true">
          <vueper-slide v-for="(banner, i) in badminBannerGroups[groupName]"
            :key="i"
            :image="`/dyn/dla/images/${banner.img_path}`"
            :data-event-title="banner.google_analytics_label"
            :data-movie-id="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].MovieID : null"
            :data-movie-title="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Title : null"
            :data-actress-name="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Actor : null"
            @click.native="carouselClick(banner)"
            v-sfw>
            <template v-slot:loader>
              <span>{{ $t('loading') }}</span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>

    <!-- carousel: tall (different ratio) -->
    <div v-if="badminBannerGroups[groupName][0].banner_type == 'carousel_tall'" class="container">
      <div class="banner">
        <vueper-slides lazy
          class="no-shadow"
          :slideRatio="540/960"
          :autoplay="true"
          :pause-on-hover="pauseOnHover"
          :speed="4500"
          :dragging-distance="70" prevent-y-scroll
          :arrowsOutside="false"
          :bullets-outside="true">
          <vueper-slide v-for="(banner, i) in badminBannerGroups[groupName]"
            :key="i"
            :image="`/dyn/dla/images/${banner.img_path}`"
            :data-event-title="banner.google_analytics_label"
            :data-movie-id="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].MovieID : null"
            :data-movie-title="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Title : null"
            :data-actress-name="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Actor : null"
            @click.native="carouselClick(banner)"
            v-sfw>
            <template v-slot:loader>
              <span>{{ $t('loading') }}</span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>

    <!-- carousel: full width -->
    <div v-if="badminBannerGroups[groupName][0].banner_type == 'carousel_full_width'">
      <div class="banner is-layered">
        <vueper-slides lazy
          class="no-shadow"
          :slideRatio="320/800"
          :autoplay="true"
          :pause-on-hover="pauseOnHover"
          :speed="4500"
          :dragging-distance="70" prevent-y-scroll
          :arrowsOutside="false"
          :bullets-outside="true">
          <vueper-slide v-for="(banner, i) in badminBannerGroups[groupName]"
            :key="i"
            :image="`/dyn/dla/images/${banner.img_path}`"
            :data-event-title="banner.google_analytics_label"
            :data-movie-id="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].MovieID : null"
            :data-movie-title="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Title : null"
            :data-actress-name="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Actor : null"
            @click.native="carouselClick(banner)"
            v-sfw>
            <template v-slot:loader>
              <span>{{ $t('loading') }}</span>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
    </div>

    <!-- slick carousel -->
    <div v-if="badminBannerGroups[groupName][0].banner_type == 'slick'" class="container">
      <div class="banner">
        <div class="slider-container">
          <div class="slider-mask mask-left"></div>
          <div class="slider-mask mask-right"></div>
          <VueSlickCarousel v-bind="slickCarouselSettings">
            <div v-for="(banner, i) in badminBannerGroups[groupName]"
              :key="i"
              @click="carouselClick(banner)">
              <img
                :src="`/dyn/dla/images/${banner.img_path}`"
                :data-event-title="banner.google_analytics_label"
                :data-movie-id="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].MovieID : null"
                :data-movie-title="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Title : null"
                :data-actress-name="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Actor : null"
                v-sfw>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>

    <!-- fixed banner: full width -->
    <div v-if="badminBannerGroups[groupName][0].banner_type == 'full_width'" class="banner is-layered">
      <div class="banner-outer" :style="{ 'background-image': 'url(/dyn/dla/images/' + badminBannerGroups[groupName][1].img_path + ')' }">
        <div class="banner-inner" @click="clickTrack('Top Page', 'Top Banner', badminBannerGroups[groupName][0].google_analytics_label);">
          <span v-if="!badminBannerGroups[groupName][0].pathname || badminBannerGroups[groupName][0].url_target === true">
            <a
              :href="badminBannerGroups[groupName][0].url"
              :data-event-title="badminBannerGroups[groupName][0].google_analytics_label"
              :data-movie-id="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].MovieID : null"
              :data-movie-title="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Title : null"
              :data-actress-name="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Actor : null"
              target="_blank"
              rel="noopener">
              <img
                class="banner-image"
                :src="`/dyn/dla/images/${badminBannerGroups[groupName][0].img_path}`"
                @click="copyToClipboard(badminBannerGroups[groupName][0].coupon_code)"
                v-sfw
              />
            </a>
          </span>
          <span v-if="badminBannerGroups[groupName][0].pathname">
            <router-link
              :to="badminBannerGroups[groupName][0].pathname"
              :data-event-title="badminBannerGroups[groupName][0].google_analytics_label"
              :data-movie-id="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].MovieID : null"
              :data-movie-title="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Title : null"
              :data-actress-name="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Actor : null">
              <img
                class="banner-image"
                :src="`/dyn/dla/images/${badminBannerGroups[groupName][0].img_path}`"
                @click="copyToClipboard(badminBannerGroups[groupName][0].coupon_code)"
                v-sfw
              />
            </router-link>
          </span>
        </div>
      </div>
    </div>

    <!-- fixed banner: responsive -->
    <div v-if="badminBannerGroups[groupName][0].banner_type == 'responsive'" class="container">
      <div class="banner" @click="clickTrack('Top Page', 'Top Banner', badminBannerGroups[groupName][0].google_analytics_label);">
        <!-- external URL -->
        <span v-if="!badminBannerGroups[groupName][0].pathname || badminBannerGroups[groupName][0].url_target === true">
          <a
            :href="badminBannerGroups[groupName][0].url"
            :data-event-title="badminBannerGroups[groupName][0].google_analytics_label"
            :data-movie-id="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].MovieID : null"
            :data-movie-title="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Title : null"
            :data-actress-name="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Actor : null"
            target="_blank"
            rel="noopener">
            <picture v-sfw>
              <source media="(max-width: 896px)" :srcset="`/dyn/dla/images/${badminBannerGroups[groupName][1].img_path}`">
              <source media="(min-width: 897px)" :srcset="`/dyn/dla/images/${badminBannerGroups[groupName][0].img_path}`">
              <img
                :src="`/dyn/dla/images/${badminBannerGroups[groupName][0].img_path}`"
                class="banner-image"
                @click="copyToClipboard(badminBannerGroups[groupName][0].coupon_code)"
              />
            </picture>
          </a>
        </span>
        <!-- same site URL -->
        <span v-if="badminBannerGroups[groupName][0].pathname">
          <router-link
            :to="badminBannerGroups[groupName][0].pathname"
            :data-event-title="badminBannerGroups[groupName][0].google_analytics_label"
            :data-movie-id="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].MovieID : null"
            :data-movie-title="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Title : null"
            :data-actress-name="(dataAttributes[badminBannerGroups[groupName][0].banner_id]) ? dataAttributes[badminBannerGroups[groupName][0].banner_id].Actor : null">
            <picture v-sfw>
              <source media="(max-width: 896px)" :srcset="`/dyn/dla/images/${badminBannerGroups[groupName][1].img_path}`">
              <source media="(min-width: 897px)" :srcset="`/dyn/dla/images/${badminBannerGroups[groupName][0].img_path}`">
              <img
                :src="`/dyn/dla/images/${badminBannerGroups[groupName][0].img_path}`"
                class="banner-image"
                @click="copyToClipboard(badminBannerGroups[groupName][0].coupon_code)"
              />
            </picture>
          </router-link>
        </span>
      </div>
    </div>

    <!-- fixed banner -->
    <div v-if="badminBannerGroups[groupName][0].banner_type == 'fixed'" class="container">
      <div v-for="banner in badminBannerGroups[groupName]"
        :key="banner.display_order"
        @click="clickTrack('Top Page', 'Top Banner', banner.google_analytics_label);"
        class="banner">
        <span v-if="!banner.pathname || banner.url_target === true">
          <a
            :href="banner.url"
            :data-event-title="banner.google_analytics_label"
            :data-movie-id="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].MovieID : null"
            :data-movie-title="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Title : null"
            :data-actress-name="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Actor : null"
            target="_blank"
            rel="noopener">
            <img
              :src="`/dyn/dla/images/${banner.img_path}`"
              class="banner-image"
              @click="copyToClipboard(banner.coupon_code)"
              v-sfw
            />
          </a>
        </span>
        <span v-if="banner.pathname">
          <router-link
            :to="banner.pathname"
            :data-event-title="banner.google_analytics_label"
            :data-movie-id="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].MovieID : null"
            :data-movie-title="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Title : null"
            :data-actress-name="(dataAttributes[banner.banner_id]) ? dataAttributes[banner.banner_id].Actor : null">
            <img
              :src="`/dyn/dla/images/${banner.img_path}`"
              class="banner-image"
              @click="copyToClipboard(banner.coupon_code)"
              v-sfw
            />
          </router-link>
        </span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */

import { VueperSlides, VueperSlide } from 'vueperslides';
import orderBy from 'lodash/orderBy';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vueperslides/dist/vueperslides.css';
import Badmin from '@/assets/js/services/Badmin';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import EventBus from '@/assets/js/utils/EventBus';

export default {
  components: { VueperSlides, VueperSlide, VueSlickCarousel },
  props: {
    // this component is also used in /freesample/, but it needs its own GA Event Category, so
    // setting up an optional prop for it
    eventCategory: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      badminBannerGroups: {},
      dataAttributes: {},
      slickCarouselSettings: {
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        centerMode: true,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 880,
            settings: {
              centerMode: false,
              variableWidth: false,
            },
          },
        ],
      },
    };
  },
  created() {
    this.bfAPI = new BifrostAPI();
    this.getBanners();
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    orderedGroupNames() {
      return orderBy(Object.keys(this.badminBannerGroups));
    },
  },
  methods: {
    async getBanners() {
      const badminSvc = new Badmin(this.$i18n.locale, this.$store.getters['user/typeID']);
      this.badminBannerGroups = await badminSvc.getBanners('top');

      // this block of logic below is needed to add data-attributes to the top page banners as part
      // of r3401. the catch here is that additional data-attributes are needed if the banner is for
      // a movie (instead of a campaign), and we don't have the needed info (movie title, actress)
      // in the banners JSON created by DL Admin. so we need to do the following so that we can have
      // this data - if they property(keyed by banner_id) exists, it's for a movie, and vue will add
      // the needed data-attributes
      Object.keys(this.badminBannerGroups).forEach((group) => {
        this.badminBannerGroups[group].forEach(async (banner) => {
          if (banner.url) {
            const urlArr = banner.url.split('//');
            const pathArr = urlArr[1].split('/');
            if (pathArr[1] === 'movies') {
              // we have a movie banner, fetch data, add to dataAttributes, keyed by banner_id
              const movieId = pathArr[2];
              const movieDetail = await this.bfAPI.getMovieDetail(movieId);
              this.$set(this.dataAttributes, banner.banner_id, movieDetail);
            }
          }
        });
      });
    },
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(this.eventCategory || category, action, label, value);
    },
    carouselClick(banner) {
      this.clickTrack('Top Page', 'Top Banner', banner.google_analytics_label);

      if (!Object.prototype.hasOwnProperty.call(banner, 'pathname') || banner.url_target === true) {
        window.open(banner.url, '_blank');
      } else {
        this.$router.push({ path: banner.pathname });
      }
    },
    copyToClipboard(msg) {
      if (msg && msg !== '') {
        this.$copyText(msg).then(
          () => {
            // copy succesful
            console.log('%ccopied to clipboard', 'color:#f0f', msg);
            EventBus.$emit('notification:event:generic', {
              text: this.$t('code_copied'),
              duration: 3000,
            });
          },
          (e) => {
            console.log('%c copyToClipboard failed!', 'color:#f00', e);
          },
        );
      }
    },
  },
  watch: {
    userTypeID(newVal, oldVal) {
      if (newVal !== oldVal) this.getBanners();
    },
  },
};
</script>

<style lang="scss">
.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 10px;
  height: 10px;
}

.vueperslides__bullet--active .default {
  background-color: #A92166;
}
</style>
