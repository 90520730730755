/* eslint-disable max-len */

import UAParser from 'ua-parser-js';
import Cookies from 'js-cookie';
import Bifrost from '.';
import store from '@/store';
import APP_CONFIG from '@/appConfig';

// aliases for readability
const CACHE_OFF = false;
const CACHE_ON = true;

export default class BifrostAPI {
  constructor() {
    this.bifrost = new Bifrost();
  }

  // TODO make this modular or at least have namespaces, to prevent collisions with method names

  // data calls that are mostly static (movie lists) or not user-specific can go in this module.
  // anything else should go in the app handlers

  getBadminBanners() {
    return this.bifrost.httpGet('/dyn/dla/json/banners.json', true);
  }

  getJoinPackages() {
    return this.bifrost.httpGet('/dyn/dla/json/join_packages.json', true);
  }

  getCampaignPackages() {
    return this.bifrost.httpGet('/dyn/dla/json/campaign_packages.json', true);
  }

  getCategories() {
    return this.bifrost.httpGet('/dyn/phpauto/category.json', true);
  }

  getSiteCategories() {
    return this.bifrost.httpGet('/dyn/dla/json/site_categories.json', true);
  }

  getSeries() {
    return this.bifrost.httpGet('/dyn/dla/json/series.json', true);
  }

  getCustomLists() {
    return this.bifrost.httpGet('/dyn/dla/json/custom_lists.json', true);
  }

  getActress(actressId) {
    const req = {
      URL: '/dyn/dla/json/actresses/{Id}.json',
      Data: {
        ID: actressId,
      },
      Cache: true,
    };
    return this.bifrost.loadFromJsonByID(req, true);
  }

  getActressList() {
    return this.bifrost.httpGet('/dyn/phpauto/actresses.json', true);
  }

  getActressEnList() {
    return this.bifrost.httpGet('/dyn/phpauto/actresses_en.json', true);
  }

  getActorsList() {
    return this.bifrost.httpGet('/dyn/phpauto/actors.json', true);
  }

  getMaintenance() {
    return this.bifrost.httpGet('/dyn/dla/json/maintenance.json', true);
  }

  getStaticPage() {
    return this.bifrost.httpGet('/dyn/dla/json/static_templates.json', true);
  }

  getFaqTypes() {
    return this.bifrost.httpGet('/dyn/dla/json/faq_types.json', true);
  }

  getFaqQuestions() {
    return this.bifrost.httpGet('/dyn/dla/json/faq_questions.json', true);
  }

  getCuratedMovieLists() {
    return this.bifrost.httpGet('/dyn/dla/json/curated_movies.json', true);
  }

  getMovieGallery(movieId, isLegacy) {
    const req = {
      URL: (!isLegacy) ? '/dyn/dla/json/movie_gallery/{Id}.json' : '/dyn/phpauto/movie_galleries/movie_id/{Id}.json',
      Data: {
        ID: movieId,
      },
      Cache: true,
    };

    return this.bifrost.loadFromJsonByID(req, true);
  }

  getMovieListNewest(offset, limit) {
    return this.bifrost.loadFromJsonMulti('/dyn/phpauto/movie_lists/list_newest_{SplitSize}.json', offset, limit, true);
  }

  getMovieListOldest(offset, limit) {
    return this.bifrost.loadFromJsonMulti('/dyn/phpauto/movie_lists/list_oldest_{SplitSize}.json', offset, limit, true);
  }

  getMovieListWeekly(offset, limit) {
    return this.bifrost.loadFromJsonMulti('/dyn/phpauto/movie_lists/list_weekly_{SplitSize}.json', offset, limit, true);
  }

  getMovieListMonthly(offset, limit) {
    return this.bifrost.loadFromJsonMulti('/dyn/phpauto/movie_lists/list_monthly_{SplitSize}.json', offset, limit, true);
  }

  getMovieListYearly(offset, limit, year) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/list_yearly_${year}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieListBOB(offset, limit) {
    return this.bifrost.loadFromJsonMulti('/dyn/phpauto/movie_lists/list_bob_{SplitSize}.json', offset, limit, true);
  }

  getMovieListNamed(offset, limit, listName) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/list_${listName}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieListActress(offset, limit, actressID) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/actresses/list_${actressID}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieListActor(offset, limit, actorID) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/actors/list_${actorID}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieListType(offset, limit, movieType) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/types/list_${movieType}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieListCondition(offset, limit, condition) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/conditions/list_${condition}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieListSeries(offset, limit, seriesID) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/series/list_${seriesID}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieListByYear(offset, limit, year) {
    return this.bifrost.loadFromJsonMulti(`/dyn/phpauto/movie_lists/years/list_${year}_{SplitSize}.json`, offset, limit, true);
  }

  getMovieDetail(movieId) {
    const req = {
      URL: '/dyn/phpauto/movie_details/movie_id/{Id}.json',
      Data: {
        ID: movieId,
      },
      Cache: true,
    };

    return this.bifrost.loadFromJsonByID(req, true);
  }

  getMovieReviews(movieId) {
    const req = {
      URL: '/dyn/phpauto/new_movie_reviews/movie_id/{Id}.json',
      Data: {
        ID: movieId,
      },
      Cache: true,
    };
    return this.bifrost.loadFromJsonByID(req, true);
  }

  getMovieListRandom(offset, limit, listtype) {
    const req = {
      AppID: 1,
      MsgID: 109,
      Data: {
        Offset: offset,
        Limit: limit,
        ListType: listtype,
      },
      Cache: true,
      CacheDuration: 60,
    };

    return this.bifrost.loadFromBackend(req);
  }

  // Bifrost (AppID 0)
  requestUserInfo() {
    const req = {
      AppID: 0,
      MsgID: 2,
      Data: null,
      Cache: false,
    };

    return this.bifrost.loadFromBackend(req);
  }

  // DLM (AppID 1)
  // FTS
  getFTS(keywords, offset = 0, limit = 36, getPPV = false) {
    const req = {
      AppID: 1,
      MsgID: 100,
      Data: {
        Offset: offset,
        Limit: limit,
        Keywords: keywords,
        GetPPV: getPPV,
      },
      Cache: true,
      CacheDuration: 60,
    };

    return this.bifrost.loadFromBackend(req);
  }

  // get movies by category
  getCategoryList(offset = 0, limit = 36, uc = null, getPPV = false) {
    const req = {
      AppID: 1,
      MsgID: 101,
      Data: {
        ListType: 'newest',
        Offset: offset,
        Limit: limit,
        UC: uc,
        GetPPV: getPPV,
      },
      Cache: true,
      CacheDuration: 60,
    };

    return this.bifrost.loadFromBackend(req);
  }

  getMovieListSEOTemplates(offset = 0, limit = 36) {
    const req = {
      AppID: 1,
      MsgID: 101,
      Data: {
        ListType: '6zQep9xnIMZM',
        Offset: offset,
        Limit: limit,
      },
      Cache: true,
      CacheDuration: 60,
    };

    return this.bifrost.loadFromBackend(req);
  }

  // get associated movies
  getCatAssoc(movieID, getPPV = false) {
    const req = {
      AppID: 1,
      MsgID: 105,
      Data: {
        MetaMovieID: parseInt(movieID, 10),
        ListType: 'catassoc',
        GetPPV: getPPV,
      },
      Cache: true,
      CacheDuration: 60,
    };
    return this.bifrost.loadFromBackend(req);
  }

  // request movie keys
  ReqMovieKeys(movieID, getPPV = false) {
    const req = {
      AppID: 1,
      MsgID: 108,
      Data: {
        MetaMovieID: parseInt(movieID, 10),
        GetPPV: getPPV,
      },
      Cache: true,
      CacheDuration: 60,
    };
    return this.bifrost.loadFromBackend(req);
  }

  // Signer
  getSignerURLs(clientID) {
    const trackingID = store.getters['vws/gaTrackingId'];

    const parser = new UAParser();
    const device = parser.getDevice();
    const deviceType = (device.type) ? 'mobile' : 'desktop';

    const url = `/api/v1/venus/signer/${trackingID}/${clientID}/${deviceType}`;

    return this.bifrost.venusRequest(url, 'GET', CACHE_ON);
  }

  // Playlists
  getPlaylists() {
    const url = '/api/v1/playlists/';
    return this.bifrost.venusRequest(url, 'GET', CACHE_OFF);
  }

  createPlaylist(playlistType, playlistName, isPublic = false) {
    const url = '/api/v1/playlists/';
    return this.bifrost.venusRequest(url, 'POST', CACHE_OFF, {
      playlist_type: playlistType,
      playlist_name: playlistName,
      is_public: isPublic,
    });
  }

  deletePlaylist(playlistId) {
    const url = '/api/v1/playlists/';
    return this.bifrost.venusRequest(url, 'DELETE', CACHE_OFF, {
      playlist_id: playlistId,
    });
  }

  updatePlaylist(playlistId, playlistType, playlistName, isPublic) {
    const url = '/api/v1/playlists/';
    return this.bifrost.venusRequest(url, 'PUT', CACHE_OFF, {
      playlist_id: playlistId,
      playlist_type: playlistType,
      playlist_name: playlistName,
      is_public: isPublic,
    });
  }

  getPlaylistMovies(playlistId, offset = 0, limit) {
    let url = `/api/v1/playlistmovies/?playlist_id=${playlistId}&offset=${offset}`;
    if (limit) url += `&limit=${limit}`;
    return this.bifrost.venusRequest(url, 'GET', CACHE_OFF);
  }

  addMovieToPlaylist(playlistId, movieMetaId) {
    const url = '/api/v1/playlistmovies/';
    return this.bifrost.venusRequest(url, 'POST', CACHE_OFF, {
      playlist_id: playlistId,
      movie_id: movieMetaId,
    });
  }

  deleteMovieFromPlaylist(playlistId, movieMetaId) {
    const url = '/api/v1/playlistmovies/';
    return this.bifrost.venusRequest(url, 'DELETE', CACHE_OFF, {
      playlist_id: playlistId,
      movie_id: movieMetaId,
    });
  }

  // Login (AppID 10)
  requestLogin(username, passwd) {
    const req = {
      AppID: 10,
      MsgID: 5,
      Data: {
        User: username,
        Pass: passwd,
      },
      noReply: true,
    };
    return this.bifrost.loadFromBackend(req);
  }

  requestLogout() {
    const req = {
      AppID: 10,
      MsgID: 4,
      Data: null,
      noReply: true,
    };

    // TODO make it less this and more something better. this is a non-reply so we
    // can't rely on a callback to resolve this, but i'd like to just have this class
    // only have data getters and no additional business logic (like setting cookies)
    Cookies.remove('NetiA', { domain: APP_CONFIG.site.domain });

    return this.bifrost.loadFromBackend(req);
  }

  // D2P (AppID 30)
  addMovieReview(movieDateId, body, rating) {
    const req = {
      AppID: 30,
      MsgID: 2,
      Data: {
        MovieDateID: movieDateId,
        Review: body,
        Rating: parseInt(rating, 10),
      },
    };

    return this.bifrost.loadFromBackend(req);
  }

  // FEL (AppID 3430)
  logJSON(data) {
    // for js-logging
    // Level: code integer+1 (http://en.wikipedia.org/wiki/Syslog#Severity_levels).
    // 1-indexed(skipping 0) to help detect hacks and malformed requests
    // App: name of module that's sending the log message (10 byte limit)
    // Msg: JSON object (1k limit)
    const SEVERITY_LEVELS = {
      emerg: 1,
      alert: 2,
      crit: 3,
      err: 4,
      warning: 5,
      notice: 6,
      info: 7,
      debug: 8,
    };
    const req = {
      AppID: 3430,
      MsgID: 1,
      Data: {
        Level: SEVERITY_LEVELS[data.Level],
        App: data.App,
        Msg: Object.keys(data.Msg).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data.Msg[k])}`).join('&'),
      },
      noReply: true,
    };

    return this.bifrost.loadFromBackend(req);
  }

  // FEL (AppID 3430)
  logToNeti(eventName, jsonData) {
    // for sending to Neti logs
    const req = {
      AppID: 3430,
      MsgID: 1,
      Data: {
        Event: eventName,
        Url: window.location.href,
        Origin: window.location.origin,
        Hostname: window.location.hostname,
        Pathname: window.location.pathname,
        Query: window.location.search,
        UserAgent: navigator.userAgent,
        Msg: Object.keys(jsonData).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(jsonData[k])}`).join('&'),
      },
      noReply: true,
    };

    return this.bifrost.loadFromBackend(req);
  }
}
