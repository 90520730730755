<i18n>
{
  "en": {
    "btn_login": "Login",
    "btn_join": "Sign Up",
    "search": "Search",
    "home": "Home",
    "movies": "Movies",
    "category": "Categories",
    "actresses": "Girls",
    "ranking": "Rankings",
    "help": "Help",
    "category_list": "All Categories",
    "series_list": "Series",
    "vip_movies": "VIP Movies",
    "svip_movies": "SVIP Movies",
    "annual_movies": "Annual Movies",
    "service_ppv": "PPV Movies",
    "service_dx": "Live Chat"
  },
  "ja": {
    "btn_login": "ログイン",
    "btn_join": "入会案内",
    "search": "検索",
    "home": "ホーム",
    "movies": "動画",
    "category": "カテゴリー",
    "actresses": "女のコ",
    "ranking": "ランキング",
    "help": "ヘルプ",
    "category_list": "カテゴリー一覧",
    "series_list": "シリーズ一覧",
    "vip_movies": "VIP動画",
    "svip_movies": "超VIP動画",
    "annual_movies": "年間パス動画",
    "service_ppv": "単品動画",
    "service_dx": "ライブチャット"
  }
}
</i18n>

<template>
  <header>
    <!-- header -->
    <div class="container">
      <div class="header-main">
        <!-- logo -->
        <div class="header-brand">
          <div class="header-logo" @click="clickTrack('Header','Home');">
            <router-link to="/"><img src="/img/common/paco-logo.svg" alt=""></router-link>
          </div>
        </div><!-- /.header-brand -->

        <div class="header-end">
          <!-- guest: login button + join button -->
          <div v-show="!isLoggedIn" class="header-guest">
            <button
              class="header-login button is-outlined"
              @click="showLogin();clickTrack('Header','Login');">{{ $t('btn_login') }}</button>
            <router-link
              to="/join/"
              class="header-join button is-filled is-color-main"
              @click="clickTrack('Header','Guide Join');"
              >{{ $t('btn_join') }}</router-link>
          </div><!-- /.header-guest -->

          <!-- logged in: user menu  -->
          <div v-show="isLoggedIn" class="header-member">
            <div class="header-menu-icon" @click="toggleUserMenu();clickTrack('Header', 'Profile');">
              <svg class="dl-icon is-larger"><use xlink:href="#dl-icon-person" /></svg>
            </div>
            <user-menu />
          </div><!-- /.header-member -->

        </div><!-- /.header-end -->
      </div><!-- /.header-main -->
    </div>

    <!-- header nav menu -->
    <div class="header-nav">
      <!-- search -->
      <div class="header-search" v-if="searchHeaderVisible">
        <div class="search-cancel" @click="searchHeaderVisible = false">
          <svg class="dl-icon"><use xlink:href="#dl-icon-arrow-left" /></svg>
        </div>
        <div class="search-box">
          <input
            type="text"
            class="search-box--input"
            :placeholder="$t('search')"
            v-model="searchQuery"
            @keydown.enter="submitSearch($event)"
            @compositionstart="composing=true"
            @compositionend="composing=false">
          <button class="search-box--search" @click="submitSearch($event)">
            <svg class="dl-icon"><use xlink:href="#dl-icon-search" /></svg>
          </button>
        </div>
      </div><!-- /.header-search -->

      <nav>
        <!-- search toggle; hide/show set via css media queries -->
        <div class="nav-item search-toggle" @click="searchHeaderVisible = true">
          <svg class="dl-icon search-toggle--search"><use xlink:href="#dl-icon-search" /></svg>
        </div>

        <!-- home -->
        <router-link
          to="/"
          class="nav-item nav-item--home"
          :class="{ 'is-active': $route.name === 'home' }">{{ $t('home') }}</router-link>

        <!-- movies -->
        <router-link
            to="/list/?page=1&o=newest"
            class="nav-item nav-item--movie"
            :class="{ 'is-active': $route.name === 'movie-list' }">{{ $t('movies') }}</router-link>

        <!-- category (menu) -->
        <div class="nav-item--category">
          <div
            class="nav-item dropdown"
            :class="{
                      'is-open': dropdowns.category,
                      'is-active': [
                          'category',
                          'series',
                          'movie-search'
                        ].includes($route.name) && !dropdowns.category
                    }"
            @click="toggleDropdown('category')">{{ $t('category') }}<i class="iconf-arrow-drop-down is-right"></i>
          </div>
          <transition name="slide">
            <div v-if="dropdowns.category" class="nav-sub-menu dropdown-content">
              <ul>
                <li class="sub-item"><router-link to="/category/" class="sub-link">{{ $t('category_list') }}<i class="sub-icon iconf-keyboard-arrow-right"></i></router-link></li>
                <li v-if="!newXPEligible" class="sub-item"><router-link to="/search/?c=50000" class="sub-link">{{ $t('vip_movies') }}<i class="sub-icon iconf-keyboard-arrow-right"></i></router-link></li>
                <li v-if="!newXPEligible" class="sub-item"><router-link to="/search/?c=50001" class="sub-link">{{ $t('svip_movies') }}<i class="sub-icon iconf-keyboard-arrow-right"></i></router-link></li>
                <li v-if="!newXPEligible" class="sub-item"><router-link to="/search/?c=50003" class="sub-link">{{ $t('annual_movies') }}<i class="sub-icon iconf-keyboard-arrow-right"></i></router-link></li>
                <li class="sub-item"><router-link to="/series/" class="sub-link">{{ $t('series_list') }}<i class="sub-icon iconf-keyboard-arrow-right"></i></router-link></li>
              </ul>
            </div>
          </transition>
        </div>

        <!-- these next 2 hide/show via css media queries; if hidden, the overflow menu appears -->
        <router-link
            to="/ranking/"
            class="nav-item nav-item--ranking"
            :class="{ 'is-active': $route.name === 'ranking' }">{{ $t('ranking') }}</router-link>

        <router-link
            to="/faq/"
            class="nav-item nav-item--help"
            :class="{ 'is-active': $route.name === 'faq' }">{{ $t('help') }}</router-link>

        <!-- overflow menu item -->
        <div class="nav-item--more">
          <div class="nav-item dropdown" :class="{ 'is-open': dropdowns.overflow }" @click="toggleDropdown('overflow')">
            <svg class="dl-icon"><use xlink:href="#dl-icon-more-vert" /></svg>
          </div>
          <transition name="slide">
            <div v-if="dropdowns.overflow" class="nav-sub-menu dropdown-content">
              <ul>
                <li class="sub-item sub-item--ranking"><router-link to="/ranking/" class="sub-link">{{ $t('ranking') }}<i class="sub-icon iconf-keyboard-arrow-right"></i></router-link></li>
                <li class="sub-item sub-item--help"><router-link to="/faq/" class="sub-link">{{ $t('help') }}<i class="sub-icon iconf-keyboard-arrow-right"></i></router-link></li>
                <li class="sub-item sub-item--ppv"><a class="sub-link" href="https://bit.ly/3YuE7h7" target="_blank" rel="noopener"><svg class="dl-icon"><use xlink:href="#dl-icon-ppv" /></svg>{{ $t('service_ppv') }}<i class="sub-icon iconf-ex-link"></i></a></li>
                <li class="sub-item sub-item--dx"><a class="sub-link" href="https://bit.ly/3Sy9V0U" target="_blank" rel="noopener"><svg class="dl-icon"><use xlink:href="#dl-icon-dx" /></svg>{{ $t('service_dx') }}<i class="sub-icon iconf-ex-link"></i></a></li>
              </ul>
            </div>
          </transition>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
/* eslint max-len: 0 */
/* eslint prefer-destructuring: 0 */

import User from '@/assets/js/services/User';
import userMenu from '@/components/_shell/menu/user/index.vue';
import EventBus from '@/assets/js/utils/EventBus';

// minimum width required for always-visible search input to be visible by default
const SEARCH_MIN_WIDTH = 640;

export default {
  components: {
    'user-menu': userMenu,
  },
  data() {
    return {
      // submenu dropdowns
      dropdowns: {
        category: false,
        overflow: false,
      },
      // search
      searchQuery: '',
      searchHeaderVisible: false,
      searchAlwaysVisible: false,
      composing: false,
    };
  },
  mounted() {
    // set search input initial state
    this.setSearchVisibilityState();
    this.$nextTick(() => {
      window.addEventListener('resize', this.setSearchVisibilityState);
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    username() {
      return this.$store.getters['user/name'];
    },
    nickname() {
      return this.$store.getters['user/nickname'];
    },
    userType() {
      return this.$store.getters['user/typeStr'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    isAnnual() {
      return this.$store.getters['user/isAnnual'];
    },
  },
  methods: {
    submitSearch(event) {
      // for Japanese IME if keycode is 229 it means "composing"
      if (event.keyCode === 229) {
        this.composing = true;
      }
      // only fire when NOT composing
      if (this.searchQuery !== '' || !this.composing || event.keyCode !== 229) {
        // reset state
        const searchQuery = this.searchQuery; // copy before we clear it
        this.searchQuery = ''; // now clear it

        // now go
        this.$router.push({ name: 'movie-search', query: { s: searchQuery } });
      }
    },
    showLogin() {
      this.$modal.show('ui-modal-login');
    },
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
    logout() {
      const userSvc = new User();
      userSvc.logout();
    },
    toggleUserMenu() {
      EventBus.$emit('userMenu:toggle', true);
    },
    toggleDropdown(menuItem) {
      this.dropdowns[menuItem] = !this.dropdowns[menuItem];
      this.closeAllDropdowns(menuItem);
    },
    closeAllDropdowns(menuItem) {
      Object.keys(this.dropdowns).forEach((key) => {
        if (!menuItem || (menuItem && key !== menuItem)) {
          this.dropdowns[key] = false;
        }
      });
    },
    setSearchVisibilityState() {
      // we want the search input to always be visible when the display is wide enough
      this.searchAlwaysVisible = (window.innerWidth >= SEARCH_MIN_WIDTH);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSearchVisibilityState);
  },
  watch: {
    $route() {
      // watch route change and close all open submenus
      this.closeAllDropdowns();

      // watch route change and close the full-width search input
      if (!this.searchAlwaysVisible) this.searchHeaderVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 400px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
