<i18n>
{
  "en": {
    "browser_title": "Home"
  },
  "ja": {
    "browser_title": "トップページ"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />

    <hero-image />

    <div id="toppage" class="page-margin">
      <!-- latest -->
      <section class="top-latest">
        <latest-list
          :num-movies="latestMovieListSize" />
      </section>

      <!-- newest -->
      <section class="top-movies">
        <movie-list
          :num-movies="newMovieListSize"
          :movie-offset="latestMovieListSize" />
      </section>

      <!-- recommended categories -->
      <section class="top-category" v-if="recommendedCategoriesEnabled && hasCategories">
        <recommended-categories
          custom-list-id="1597082345100-7e32e96f-98eb"
          :movies-per-tab="recommendedCategoriesMovieListSize"
          @categoriesEval="categoriesEval" />
      </section>

      <!-- recommended series -->
      <section class="top-series" v-if="recommendedSeriesEnabled && hasSeries && locale == 'ja'">
        <recommended-series
          custom-list-id="1600875418518-75d8b951-b0df"
          @seriesEval="seriesEval" />
      </section>

      <!-- PR -->
      <section class="top-pr" v-if="prEnabled && hasPR">
        <pr-list @prEval="prEval"/>
      </section>
    </div><!-- /#toppage -->
  </main>
</template>

<script>
/* eslint max-len: 0 */

// @ is an alias to /src
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';

import SiteConfig from '@/assets/js/utils/SiteConfig';
import lazyLoadComponent from '@/assets/js/utils/lazyLoadComponent';
import SkeletonBox from '@/components/_shell/SkeletonBox.vue';

import HeroImage from '@/components/home/heroImage.vue';
import LatestList from '@/components/home/latestList.vue';
import MovieList from '@/components/home/movieList.vue';

const skeletonOptions = {
  loading: SkeletonBox,
  loadingData: {
    props: {
      width: '100%',
      height: '20em',
    },
  },
};

export default {
  name: 'Home',

  components: {
    'vue-headful': vueHeadful,
    'hero-image': HeroImage,
    'latest-list': LatestList,
    'movie-list': MovieList,
    'recommended-categories': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/recommendedCategories.vue'),
    }),
    'recommended-series': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/recommendedSeries.vue'),
    }),
    'pr-list': lazyLoadComponent({
      ...skeletonOptions,
      componentFactory: () => import('@/components/home/PR.vue'),
    }),
  },
  data() {
    return {
      config: {},
      hasCategories: true,
      hasSeries: true,
      hasPR: true,
      showCategories: true,
      showSeries: true,
      showPR: true,
      dynamicTitle: '',
    };
  },

  async created() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);

    // get site config (settings from admin)
    this.config = await SiteConfig.getConfig('home');
  },

  computed: {
    locale() {
      return this.$i18n.locale;
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    // set default site configs below, in case the app fails to fetch the config json file
    latestMovieListSize() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'latestMovieListSize') ? this.config.latestMovieListSize : 2);
    },
    newMovieListSize() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'newMovieListSize') ? this.config.newMovieListSize : 24);
    },
    recommendedCategoriesEnabled() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedCategories') ? this.config.recommendedCategories : true);
    },
    recommendedCategoriesMovieListSize() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedCategoriesMovieListSize') ? this.config.recommendedCategoriesMovieListSize : 7);
    },
    recommendedSeriesEnabled() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'recommendedSeries') ? this.config.recommendedSeries : true);
    },
    prEnabled() {
      return (Object.prototype.hasOwnProperty.call(this.config, 'prEnabled') ? this.config.prEnabled : true);
    },
  },

  methods: {
    categoriesEval(value) {
      this.hasCategories = value;
    },
    seriesEval(value) {
      this.hasSeries = value;
    },
    prEval(value) {
      this.hasPR = value;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_home.scss';
</style>
