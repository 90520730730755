<i18n>
{
  "en": {
    "site_guide": "Site Guide",
    "home": "Home",
    "popular_movie_ranking": "Rankings",
    "sign_up": "Sign Up",
    "actresses": "Girls",
    "upgrade": "Upgrade",
    "highspeed_plans": "Highspeed Plan",
    "movies": "Movies",
    "all_movies_list": "All Movies",
    "categories": "Categories",
    "series": "Series",
    "vip_movies": "VIP Movies",
    "svip_movies": "SVIP Movies",
    "annual_movies": "Annual Movies",
    "help": "Help",
    "member_services": "Member Services",
    "contact": "Contact",
    "site_map": "Sitemap",
    "content_removal": "Content Removal",
    "agreement": "Agreement",
    "privacy": "Privacy",
    "recommended_sites": "Recommended Sites",
    "site_carib": "Caribbeancom",
    "site_caribpr": "Caribbeancom Premium",
    "site_dxlive": "DX LIVE",
    "site_1pondo": "1pondo",
    "site_10musume": "10musume",
    "site_heyzo": "HEYZO"
  },
  "ja": {
    "site_guide": "サイトガイド",
    "home": "ホーム",
    "popular_movie_ranking": "人気ランキング",
    "sign_up": "入会案内",
    "actresses": "女のコ",
    "upgrade": "アップグレード案内",
    "highspeed_plans": "ハイスピードプラン",
    "movies": "動画",
    "all_movies_list": "全動画リスト",
    "categories": "カテゴリー",
    "series": "シリーズ",
    "vip_movies": "VIP動画",
    "svip_movies": "超VIP動画",
    "annual_movies": "年間パス動画",
    "help": "ヘルプ",
    "member_services": "会員サービス",
    "contact": "お問い合わせ",
    "site_map": "サイトマップ",
    "content_removal": "削除依頼",
    "agreement": "利用規約",
    "privacy": "プライバシー",
    "recommended_sites": "おすすめサイト",
    "site_carib": "カリビアンコム",
    "site_caribpr": "カリビアンコムプレミアム",
    "site_dxlive": "DXLIVE",
    "site_1pondo": "一本道",
    "site_10musume": "天然むすめ",
    "site_heyzo": "HEYZO"
  }
}
</i18n>

<template>
  <footer>
    <div class="container">
      <div class="footer-return">
        <!-- scroll to top button; scroll to "page" id on App.vue -->
        <a href="#page" v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }"><img src="/img/common/footer-return-arrow.svg" alt=""></a>
      </div>
      <div class="footer-main">
        <div :class="{'footer-menu': newXPEligible, 'footer-menu-classic': !newXPEligible}">
          <ul>
            <li class="menu-title" @click="clickTrack('Footer','Home');"><router-link to="/">{{ $t('home') }}</router-link></li>
            <li v-if="!isSiteMember" class="menu-title" @click="clickTrack('Footer','Guide Join');"><router-link to="/join/">{{ $t('sign_up') }}</router-link></li>
            <li class="menu-title" @click="clickTrack('Footer','Ranking');"><router-link to="/ranking/">{{ $t('popular_movie_ranking') }}</router-link></li>
          </ul>
        </div>
        <div :class="{'footer-menu': newXPEligible, 'footer-menu-classic': !newXPEligible}">
          <div class="menu-label" :class="{ 'is-open' : menuSelected == 'movies' }" @click="toggleMenu('movies', $event)">{{ $t('movies') }}<i class="iconf-add footer-menu-icon"></i></div>
          <div class="footer-menu-contents">
            <ul>
              <li @click="clickTrack('Footer','All List');"><router-link to="/list/?page=1&o=newest">{{ $t('all_movies_list') }}</router-link></li>
              <li @click="clickTrack('Footer','Category');"><router-link to="/category/">{{ $t('categories') }}</router-link></li>
              <li @click="clickTrack('Footer','Series');"><router-link to="/series/">{{ $t('series') }}</router-link></li>
              <li v-if="!newXPEligible" @click="clickTrack('Footer','VIP Movies');"><router-link to="/search/?c=50000">{{ $t('vip_movies') }}</router-link></li>
              <li v-if="!newXPEligible" @click="clickTrack('Footer','SVIP Movies');"><router-link to="/search/?c=50001">{{ $t('svip_movies') }}</router-link></li>
              <li v-if="!newXPEligible" @click="clickTrack('Footer','Annual Movies');"><router-link to="/search/?c=50003">{{ $t('annual_movies') }}</router-link></li>
            </ul>
          </div>
        </div>
        <div class="footer-menu-classic" v-if="!newXPEligible">
          <div class="menu-label" @click="toggleMenu('member', $event)" :class="{ 'is-open' : menuSelected == 'member' }">{{ $t('member_services') }}<i class="iconf-add footer-menu-icon"></i></div>
          <div class="footer-menu-contents">
            <ul>
              <li @click="clickTrack('Footer','Guide Upgrade');"><router-link to="/upgrade/">{{ $t('upgrade') }}</router-link></li>
              <li @click="clickTrack('Footer','Highspeed Plan');"><router-link to="/highspeed/">{{ $t('highspeed_plans') }}</router-link></li>
            </ul>
          </div>
        </div>
        <div :class="{'footer-menu': newXPEligible, 'footer-menu-classic': !newXPEligible}">
          <div class="menu-label" @click="toggleMenu('guide', $event)" :class="{ 'is-open' : menuSelected == 'guide' }">{{ $t('site_guide') }}<i class="iconf-add footer-menu-icon"></i></div>
          <div class="footer-menu-contents">
            <ul>
              <li @click="clickTrack('Footer','Help');"><router-link to="/faq/">{{ $t('help') }}</router-link></li>
              <li @click="clickTrack('Footer','Contact');"><router-link to="/contact/">{{ $t('contact') }}</router-link></li>
              <li @click="clickTrack('Footer','Sitemap');"><router-link to="/sitemap/">{{ $t('site_map') }}</router-link></li>
              <li @click="clickTrack('Footer','Agreement');"><router-link to="/agreement/">{{ $t('agreement') }}</router-link></li>
              <li @click="clickTrack('Footer','Privacy');"><router-link to="/privacy/">{{ $t('privacy') }}</router-link></li>
              <li @click="clickTrack('Footer','Content Removal');"><a href="https://service.allbrightinformation.com/contents/inquiry/" target="_blank" rel="noopener">{{ $t('content_removal') }}</a></li>
            </ul>
          </div>
        </div>
        <div :class="{'footer-menu': newXPEligible, 'footer-menu-classic': !newXPEligible}">
          <div class="menu-label" @click="toggleMenu('sites', $event)" :class="{ 'is-open' : menuSelected == 'sites' }">{{ $t('recommended_sites') }}<i class="iconf-add footer-menu-icon"></i></div>
          <div class="footer-menu-contents">
            <ul>
              <li @click="clickTrack('Footer','Caribbeancom');"><a href="https://bit.ly/3YNngGL" target="_blank" rel="noopener">{{ $t('site_carib') }}<i class="iconf-ex-link is-right"></i></a></li>
              <li @click="clickTrack('Footer','Caribbeancompr');"><a href="https://bit.ly/3YuE7h7" target="_blank" rel="noopener">{{ $t('site_caribpr') }}<i class="iconf-ex-link is-right"></i></a></li>
              <li @click="clickTrack('Footer','1pondo');"><a href="https://bit.ly/4dqfMNR" target="_blank" rel="noopener">{{ $t('site_1pondo') }}<i class="iconf-ex-link is-right"></i></a></li>
              <li @click="clickTrack('Footer','10musume');"><a href="https://bit.ly/4dLkRk7" target="_blank" rel="noopener">{{ $t('site_10musume') }}<i class="iconf-ex-link is-right"></i></a></li>
              <li @click="clickTrack('Footer','DXLive');"><a href="https://bit.ly/3Sy9V0U" target="_blank" rel="noopener">{{ $t('site_dxlive') }}<i class="iconf-ex-link is-right"></i></a></li>
            </ul>
          </div>
        </div>
      </div><!-- /.footer-main -->
      <ul v-if="locale === 'ja'" class="footer-other">
        <li @click="clickTrack('Footer','Access Ranking');"><a href="http://www.adultmango.com/cgi-bin/ranklink.cgi?id=paco2" target="_blank" rel="noopener">アクセスランキング<i class="iconf-ex-link is-right"></i></a></li>
        <li @click="clickTrack('Footer','Affiliate');"><a href="https://bit.ly/3LWrC6p" target="_blank" rel="noopener">アダルトアフィリエイト<i class="iconf-ex-link is-right"></i></a></li>
        <li @click="clickTrack('Footer','Chat Performer');"><a href="https://bit.ly/3AonERw" target="_blank" rel="noopener">チャットレディのお仕事<i class="iconf-ex-link is-right"></i></a></li>
        <li @click="clickTrack('Footer','Business Partner');"><a href="https://www.d2pass.com/form/business_partner/" target="_blank" rel="noopener">ビジネスパートナー<i class="iconf-ex-link is-right"></i></a></li>
      </ul>
      <div class="footer-sub">
        <div class="footer-sub--brand">
          <router-link to="/"><img class="footer-logo" src="/img/common/paco-logo.svg" alt=""></router-link>
        </div>
        <div class="footer-sub--copyright">
          <div>&copy; {{ currentYear }} pacopacomama.com</div>
          <div>v{{ appVersion }}</div>
        </div>
        <div class="footer-sub--language" v-if="locale == 'ja'" @click="clickTrack('Footer','English');">
          <a href="https://en.pacopacomama.com"><img src="/img/common/la-switcher-jp.svg" alt=""></a>
        </div>
        <div class="footer-sub--language" v-if="locale == 'en'" @click="clickTrack('Footer','Japanese');">
          <a href="https://www.pacopacomama.com"><img src="/img/common/la-switcher-en.svg" alt=""></a>
        </div>
      </div><!-- /.footer-sub -->
    </div>
    <div class="footer-extra">
      <div class="container">
        <router-link to="/2257/">18 USC &#167; 2257 Record-Keeping Requirements</router-link>
        <router-link to="/human-rights-policies/">Human Rights Policies</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
/* eslint max-len: 0 */
import APP_CONFIG from '@/appConfig';

export default {
  data() {
    return {
      menuSelected: undefined,
    };
  },
  created() {
    // non-reactive consts
    this.appHeaderHeight = APP_CONFIG.site.headerHeight;
    this.currentYear = (new Date()).getFullYear();
    this.appVersion = APP_CONFIG.app.version;
  },
  methods: {
    toggleMenu(option, event) {
      const el = event.target;
      if (el.classList.contains('is-open')) {
        el.classList.toggle('is-open');
        this.menuSelected = undefined;
      } else {
        this.menuSelected = option;
      }
    },
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
