<template>
<div>
  <div class="meta-age" v-if="ageTags.length > 0">
    <div class="meta-age--tag" v-for="(ageTag, index) in ageTags" :key="index">{{ ageTag }}</div>
  </div>
  <div class="meta-data" v-else>---</div>
</div>
</template>

<script>
/* eslint max-len: 0 */

// these are in a weird order because the ages are out of order in the db, so these IDs correpond
// to age groups 20s, 30s, 40s, 50s
const AGE_CATEGORIES = [89, 90, 88, 87];

export default {
  props: {
    movie: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ageTags: [],
    };
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.movie, 'UC') && this.movie.UC.length > 0) {
      AGE_CATEGORIES.forEach((categoryID) => {
        const idx = this.movie.UC.indexOf(categoryID);
        if (idx >= 0) {
          this.ageTags.push((this.locale === 'en') ? this.movie.UCNAMEEn[idx] : this.movie.UCNAME[idx]);
        }
      });
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
