/* eslint no-param-reassign: 0 */

const mutations = {
  SET_SFW_MODE(state, paranoiaLevel) {
    state.sfwMode = paranoiaLevel;
  },

  SET_MOVIE_LIST_FILTER_VISIBILITY(state, bool) {
    state.movieListFilterVisible = bool;
  },

  SET_MENU_ALWAYS_VISIBLE(state, bool) {
    state.menuAlwaysVisible = bool;
  },
};

export default mutations;
