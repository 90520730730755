const actions = {
  setSfwMode(context, paranoiaLevel) {
    context.commit('SET_SFW_MODE', Number(paranoiaLevel));
  },

  setMovieListFilterVisibility(context, bool) {
    context.commit('SET_MOVIE_LIST_FILTER_VISIBILITY', bool);
  },

  setMenuAlwaysVisible(context, bool) {
    context.commit('SET_MENU_ALWAYS_VISIBLE', bool);
  },
};

export default actions;
