/**
 * @module Core/Providers/Movie
 * @description Helper that provides movie data structures
 * @example
 * // returns the movie detail object with additional movie properties
  BfAPI.getMovieDetail(MovieID).then(function(detail) {
    this.movieDetails = Movie.expandDetails(detail);
  });
 */

/* eslint max-len: 0 */

import moment from 'moment';
import includes from 'lodash/includes';
import Time from './time';

/**
 * @function
 * @name expandDetails
 * @description parses the movie details object and adds extra properties (for readability, simplifying logic on templates, etc)
 * @returns {object} expanded movie details with additional properties
 * @property {array} ActorList list of actresses
 * @property {string} ReleaseDate formatted movie release date (ex '2017/05/27')
 * @property {string} ExpireDate formatted movie expiration date
 * @property {string} DurationFmt length of movie (ex. '01:07:13')
 * @property {string} DurationSEO length of movie (ex. 'T1H7M13S')
 * @property {boolean} isRegular true if regular movie
 * @property {boolean} isVIP true if VIP movie
 * @property {boolean} isSVIP true if SVIP movie
 * @property {boolean} isRecurring true if movie is only available to recurring users
 * @property {boolean} isAnnual true if movie is only available to annual users
 * @property {boolean} isExpired true if the movie has expired (can no longer be played/downloaded)
 * @property {boolean} isLimited true if the movie has been flagged as "limited time" playback/download
 * @property {boolean} isGuestExcluded true if the movie is hidden from non-members
 */

const VR_CATEGORY_ID = 94;

const expandDetails = (movieDetails, locale, userTypeID) => {
  const expandedDetails = movieDetails;

  if (Object.prototype.hasOwnProperty.call(movieDetails, 'Actor') && movieDetails.Actor !== null) expandedDetails.ActorList = movieDetails.Actor.split(',');
  if (Object.prototype.hasOwnProperty.call(movieDetails, 'Release') && movieDetails.Release !== null) expandedDetails.ReleaseDate = movieDetails.Release.replace(/-/g, '/');
  if (Object.prototype.hasOwnProperty.call(movieDetails, 'Duration') && movieDetails.Duration !== null) expandedDetails.DurationFmt = Time.formatSeconds(parseInt(movieDetails.Duration, 10));
  if (Object.prototype.hasOwnProperty.call(movieDetails, 'Duration') && movieDetails.Duration !== null) expandedDetails.DurationSEO = Time.formatSecondsSEO(parseInt(movieDetails.Duration, 10));

  // movie types + conditions
  expandedDetails.isRegular = false;
  expandedDetails.isVIP = false;
  expandedDetails.isSVIP = false;
  expandedDetails.isRecurring = false;
  expandedDetails.isAnnual = false;
  expandedDetails.IsExpired = false;
  expandedDetails.IsLimited = false;
  expandedDetails.isGuestExcluded = false;
  expandedDetails.isVR = false;

  // default thumbnail
  expandedDetails.thumbnailSrc = `/assets/sample/${movieDetails.MovieID}/l_thum.jpg`;

  switch (expandedDetails.Type) {
    case 0: {
      expandedDetails.isRegular = true;
      break;
    }
    case 1: {
      expandedDetails.isVIP = true;
      break;
    }
    case 2: {
      expandedDetails.isSVIP = true;
      break;
    }
    default: {
      break;
    }
  }

  if (Object.prototype.hasOwnProperty.call(movieDetails, 'Conditions') && movieDetails.Conditions !== null) {
    if (includes(movieDetails.Conditions, 4)) { expandedDetails.isRecurring = true; }
    if (includes(movieDetails.Conditions, 5)) { expandedDetails.isAnnual = true; }
  }

  // add some data props if we have an expire date set for this movie
  if (Object.prototype.hasOwnProperty.call(movieDetails, 'Expire') && movieDetails.Expire !== null && movieDetails.Expire !== '') {
    if (movieDetails.Expire.includes('+')) {
      // JSON Expire includes date + timestamp + timezone
      expandedDetails.ExpireTimezone = movieDetails.Expire;
    } else if (movieDetails.Expire.includes(':')) {
      // JSON Expire includes date + timestamp; add timezone
      expandedDetails.ExpireTimezone = `${movieDetails.Expire}+00:00`;
    } else {
      // JSON Expire includes date; add timestamp + add timezone
      expandedDetails.ExpireTimezone = `${movieDetails.Expire} 14:59:59+00:00`;
    }

    const nowDate = moment();
    const expireDate = moment(expandedDetails.ExpireTimezone, moment.defaultFormat);

    // remove timestamp portion and only get the date (for showing the date range in movie details)
    // and reformat date separators from '-' to '/'
    expandedDetails.ExpireDate = expireDate.format('Y/MM/DD');
    expandedDetails.ExpireTime = expireDate.format('HH:mm:ss');

    // add some booleans for cleaner templates
    if (expireDate.isBefore(nowDate)) {
      expandedDetails.IsExpired = true;
      expandedDetails.IsLimited = false;
    } else {
      expandedDetails.IsExpired = false;
      expandedDetails.IsLimited = true;
    }
  }

  // set isGuestExcluded flag if the SampleExcludeFlag' is set in movie details JSON
  if (Number.isInteger(userTypeID)
    && userTypeID === 0
    && movieDetails.SampleExcludeFlag) expandedDetails.isGuestExcluded = true;

  // set isVR flag if the movie has the VR category ID
  if (Object.prototype.hasOwnProperty.call(movieDetails, 'UC') && Array.isArray(movieDetails.UC) && movieDetails.UC.includes(VR_CATEGORY_ID)) expandedDetails.isVR = true;

  return expandedDetails;
};

const expandDetailsList = (movieDetailsList, locale, userTypeID) => {
  const expandedDetailList = movieDetailsList;

  for (let i = 0; i < movieDetailsList.length; i += 1) {
    expandedDetailList[i] = expandDetails(movieDetailsList[i], locale, userTypeID);
  }
  return expandedDetailList;
};

const filterExcludedMovieList = (movieDetailsList, userTypeID) => {
  function filterExcluded(movie) {
    if (
      // filter movies flagged to exclude guests from guest users
      (userTypeID === 0 && movie.isGuestExcluded)

      // filter VR movies from streaming users after the snap
      || ((userTypeID === 200 || userTypeID === 20 || userTypeID % 10 === 9) && Object.prototype.hasOwnProperty.call(movie, 'UC') && Array.isArray(movie.UC) && movie.UC.includes(VR_CATEGORY_ID) && process.env.VUE_APP_NEW_XP_ENABLED === 'true' && process.env.VUE_APP_NEW_XP_HIDE_VR === 'true')

      // filter VR movies from guests after the snap
      || (userTypeID === 0 && Object.prototype.hasOwnProperty.call(movie, 'UC') && Array.isArray(movie.UC) && movie.UC.includes(VR_CATEGORY_ID) && process.env.VUE_APP_NEW_XP_ENABLED === 'true' && process.env.VUE_APP_NEW_XP_HIDE_VR === 'true')
    ) {
      return false;
    }
    return true;
  }
  return movieDetailsList.filter(filterExcluded);
};

export default { expandDetails, expandDetailsList, filterExcludedMovieList };
