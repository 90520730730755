<i18n>
{
  "en": {
    "account_info": "Account Information",
    "streaming_member": "Streaming Member",
    "member": "Member",
    "vip_member": "VIP Member",
    "svip_member": "SVIP Member",
    "annual_member": "Annual Member",
    "join": "Sign Up",
    "upgrade": "Upgrade",
    "logout": "Log out",
    "dml_title": "Today's Download",
    "dml_remaining_0": "Remaining: ",
    "dml_remaining_1": " ",
    "dml_separator": " / ",
    "dml_total_0": "Total: ",
    "dml_total_1": " ",
    "dml_link": "Add More Downloads",
    "dml_history": "Download History",
    "pl_title": "My Playlist",
    "pl_favorites": "Favorites",
    "pl_watch_later": "Watch Later",
    "pl_setting": "Manage Playlist",
    "pl_beta": "BETA",
    "vchat_points": "Chat Points",
    "vchat_link": "Live Chat"
  },
  "ja": {
    "account_info": "アカウント情報",
    "streaming_member": "ストリーミング会員",
    "member": "一般会員",
    "vip_member": "VIP会員",
    "svip_member": "超VIP会員",
    "annual_member": "年間会員",
    "join": "入会案内",
    "upgrade": "アップグレード",
    "logout": "ログアウト",
    "dml_title": "本日のダウンロード",
    "dml_remaining_0": "残り ",
    "dml_remaining_1": " ",
    "dml_separator": " / ",
    "dml_total_0": "全",
    "dml_total_1": "本",
    "dml_link": "ダウンロード本数を追加する",
    "dml_history": "ダウンロード履歴",
    "pl_title": "再生リスト",
    "pl_favorites": "お気に入り",
    "pl_watch_later": "後で見る",
    "pl_setting": "設定",
    "pl_beta": "β版",
    "vchat_points": "保有チャットポイント",
    "vchat_link": "ライブ配信を見る"
  }
}
</i18n>

<template>
<transition name="user-menu">
  <div class="header-member-info" v-if="menuVisible">
    <div class="member-info-section--account">
      <dl>
        <!-- overrides -->
        <dt v-if="!newXPEligible && (descriptionOverride && userBilling !== null) && descriptionOverride[userBilling.PackageID]" class="member-info-title">{{ $t(descriptionOverride[userBilling.PackageID]) }}</dt>
        <!-- normal logic -->
        <dt v-else-if="userType === 'NonMember'" class="member-info-title">{{ $t('account_info') }}</dt>
        <dt v-else-if="userClass === 'Streaming'" class="member-info-title">{{ $t('streaming_member') }}</dt>
        <dt v-else-if="!newXPEligible && userClass === 'Download' && isAnnual" class="member-info-title">{{ $t('annual_member') }}</dt>
        <dt v-else-if="!newXPEligible && userClass === 'Download' && userType === 'SVIP'" class="member-info-title">{{ $t('svip_member') }}</dt>
        <dt v-else-if="!newXPEligible && userClass === 'Download' && userType === 'VIP'" class="member-info-title">{{ $t('vip_member') }}</dt>
        <dt v-else class="member-info-title">{{ $t('member') }}</dt>
        <dd class="member-info-data" @click="showUserDebug()">{{ username }}</dd>
        <dd class="member-info-data" v-if="locale == 'ja'"><a href="https://www.d2pass.com/edit?NetiFL=1" target="_blank" rel="noopener">アカウント設定<i class="iconf-ex-link is-right"></i></a></dd>
        <dd class="member-info-data" v-if="locale == 'en'"><a href="https://www.d2pass.com/edit?NetiFL=1&lang=en" target="_blank" rel="noopener">Account Settings<i class="iconf-ex-link is-right"></i></a></dd>
      </dl>
    </div>
    <div class="member-info-section--download" v-if="userClass === 'Download' && dmlEnabled && slotsTotal < 999">
      <dl>
        <dt class="member-info-title">
          {{ $t('dml_title') }}
          <span>{{ $t('dml_total_0') }}{{ slotsTotal }}{{ $t('dml_total_1') }}{{ $t('dml_separator') }}{{ $t('dml_remaining_0') }}{{ slotsFree }}{{ $t('dml_remaining_1') }}</span>
        </dt>
        <dd class="member-info-data">
          <div class="member-info-dml">
            <span class="member-info-dml--used" :style="`width: ${dmlRatio}%;`"></span>
          </div>
        </dd>
        <!--<dd class="member-info-data"><router-link to="/dml/">{{ $t('dml_link') }} <i class="iconf-keyboard-arrow-right"></i></router-link></dd>-->
        <dd class="member-info-data"><router-link to="/downloadhistory/">{{ $t('dml_history') }} <i class="iconf-keyboard-arrow-right"></i></router-link></dd>
      </dl>
    </div>
    <div class="member-info-section--playlist" v-if="playlistEnabled">
      <dl>
        <dt class="member-info-title">{{ $t('pl_title') }}<span class="beta-tag">{{ $t('pl_beta') }}</span></dt>
        <dd class="member-info-data"><router-link :to="`/playlist/?list=${likedPlaylistId}`">{{ $t('pl_favorites') }} <i class="iconf-keyboard-arrow-right"></i></router-link></dd>
        <dd class="member-info-data"><router-link :to="`/playlist/?list=${watchLaterPlaylistId}`">{{ $t('pl_watch_later') }} <i class="iconf-keyboard-arrow-right"></i></router-link></dd>
        <dd class="member-info-data"><router-link to="/playlists/">{{ $t('pl_setting') }} <i class="iconf-keyboard-arrow-right"></i></router-link></dd>
      </dl>
    </div>
    <div class="member-info-section--coupon" v-if="0">
      <dl>
        <dt class="member-info-title">単品購入クーポン<span>5枚</span></dt>
        <!--<dd class="member-info-data"><a href="javascript:void(0)">保有クーポンを確認する<i class="iconf-ex-link"></i></a></dd><-->
      </dl>
    </div>
    <div class="member-info-section--chat" v-if="isSiteMember">
      <dl>
        <dt class="member-info-title">{{ $t('vchat_points') }}<span>{{ vcPoints }} pt</span></dt>
        <dd class="member-info-data"><a href="https://www.dxlive.com/" target="_blank" rel="noopener">{{ $t('vchat_link') }}<i class="iconf-ex-link is-right"></i></a></dd>
      </dl>
    </div>
    <transition name="slide">
    <div class="member-info-section--debug" v-if="userDebug.isVisible">
      <dl>
        <dt class="member-info-title">Type ID<span>{{ userTypeID }}</span></dt>
        <dt class="member-info-title">Class<span>{{ userClass }}</span></dt>
        <dt class="member-info-title">Tier<span>{{ userTier }}</span></dt>
        <dt class="member-info-title">Annual<span>{{ isAnnual ? 'Yes' : 'No' }}</span></dt>
        <dt class="member-info-title">Active Package ID<span>{{ this.userBilling.PackageID }}</span></dt>
        <dt class="member-info-title">Active Package Duration<span>{{ this.userBilling.PackageDuration }}</span></dt>
      </dl>
    </div>
    </transition>
    <div class="member-info-section--cta">
      <button @click="logout()" class="member-info-button is-logout button is-filled is-small">{{ $t('logout') }}</button>
      <router-link v-if="!isSiteMember" to="/join/">
        <button class="member-info-button button is-filled is-small is-color-main">{{ $t('join') }}</button>
      </router-link>
      <router-link v-if="!newXPEligible && isSiteMember && !isAnnual" to="/upgrade/">
        <button class="member-info-button button is-filled is-small is-color-main" @click="closeMenu()">{{ $t('upgrade') }}</button>
      </router-link>
    </div>
  </div>
</transition>
</template>

<script>
/* eslint max-len: 0 */
import User from '@/assets/js/services/User';
import EventBus from '@/assets/js/utils/EventBus';
import APP_CONFIG from '@/appConfig';

const LIKED_PLAYLIST_NAME = 'liked';
const WATCH_LATER_PLAYLIST_NAME = 'watch_later';

export default {
  props: {
    // ex: <user-menu :description-override="{ 20032036: 'streaming_legacy' }" />
    // will show users with this active package as a "streaming_legacy" user, even though their
    // permissions are set up as SVIP/Annual (this is a legacy 10m package) and would have appeared
    // as a "streaming svip" user
    descriptionOverride: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      menuVisible: false,

      // show user info secretly 🤫
      userDebug: {
        isVisible: false,
        clickCount: 0,
      },
    };
  },
  created() {
    // non-reactive consts
    this.dmlEnabled = APP_CONFIG.dml.enabled;

    // set listener for menu toggle (from header component)
    EventBus.$on('userMenu:toggle', () => {
      this.menuVisible = !this.menuVisible;

      // refresh DML state
      if (this.menuVisible && this.dmlEnabled) this.$store.dispatch('dml/refreshStatus');
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    username() {
      return this.$store.getters['user/name'];
    },
    nickname() {
      return this.$store.getters['user/nickname'];
    },
    userType() {
      return this.$store.getters['user/typeStr'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    userClass() {
      return this.$store.getters['user/class'];
    },
    userTier() {
      return this.$store.getters['user/tier'];
    },
    userBilling() {
      return this.$store.getters['user/billing'];
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    isAnnual() {
      return this.$store.getters['user/isAnnual'];
    },
    vcBalance() {
      return this.$store.getters['user/vcBalance'];
    },
    vcCoupon() {
      return this.$store.getters['user/vcCoupon'];
    },
    vcPoints() {
      const sum = parseFloat(this.vcBalance) + parseFloat(this.vcCoupon);
      return sum.toFixed(2);
    },
    slotsUsed() {
      return this.$store.getters['dml/slotsUsed'];
    },
    slotsFree() {
      return this.$store.getters['dml/slotsFree'];
    },
    slotsTotal() {
      return this.slotsUsed + this.slotsFree;
    },
    dmlRatio() {
      const ratio = (this.slotsFree / this.slotsTotal) * 100;
      return 100 - Math.round(ratio); // remove the 100 subtraction if we want an empty bar to mean "no downloads left"
    },
    playlistEnabled() {
      return this.$store.getters['playlist/isEnabled'];
    },
    defaultPlaylistIds() {
      return this.$store.getters['playlist/defaultPlaylistIDs'];
    },
    likedPlaylistId() {
      return this.defaultPlaylistIds[LIKED_PLAYLIST_NAME];
    },
    watchLaterPlaylistId() {
      return this.defaultPlaylistIds[WATCH_LATER_PLAYLIST_NAME];
    },
  },
  methods: {
    logout() {
      const userSvc = new User();
      userSvc.logout();
      this.closeMenu();
    },
    closeMenu() {
      this.menuVisible = false;
    },
    showUserDebug() {
      if (this.userDebug.clickCount > 11 && this.username.match('.d2p')) this.userDebug.isVisible = true;
      this.userDebug.clickCount += 1;
    },
  },
  watch: {
    // watch route change and close the menu when it changes. note: not using arrow functions here
    // because I need to bind to 'this' and it's just easier to just name the function to get around
    // this limitation
    '$route.path': function watchRoutePath() {
      this.menuVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-menu-enter-active, .user-menu-leave-active {
  transition: opacity .25s;
}
.user-menu-enter, .user-menu-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition-duration: 1.0s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 1.0s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 160px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

/* temp beta tag style */
.member-info-title span.beta-tag {
  right: auto;
  font-size: 0.7em;
  line-height: 1.6em;
  vertical-align: middle;
  margin-left: 0.5em;
  padding: 1px 5px;
  color: #fff;
  background-color: #999;
  border-radius: 3px;
}
</style>
