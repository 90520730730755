<template>
  <modal name="ui-modal-video" @before-open="beforeOpen" :height="'auto'" :classes="'v--modal-override'" :resizable="true" :adaptive="true" :draggable="false" :clickToClose="true">

  <div class="block--age bg-white">
    <video :id="`sample-video-${epoch}`" class="video-js vjs-big-play-centered" playsinline></video>
  </div>
  </modal>
</template>


<script>
/* eslint max-len: 0 */
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import VideoJS from '@/assets/js/services/Video/VideoJS';
import PlayerFactory from '@/assets/js/services/Video/PlayerFactory';

export default {
  props: {
    videoSrc: {
      type: String,
    },
  },
  data() {
    return {
      movieId: null,
      movieDetail: null,
      videoPlayerInstance: null,
      epoch: null,
    };
  },
  mounted() {
  },
  computed: {
    userIsSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    userTypeStr() {
      return this.$store.getters['user/typeStr'];
    },
    userIsRecurring() {
      return this.$store.getters['user/isRecurring'];
    },
    userIsAnnual() {
      return this.$store.getters['user/isAnnual'];
    },
    userIsTrial() {
      return this.$store.getters['user/isTrial'];
    },
  },
  methods: {
    beforeOpen(event) {
      // handle params passed in as component attributes
      if (event.params.movieId) {
        this.movieId = event.params.movieId;

        const bfAPI = new BifrostAPI();
        bfAPI.getMovieDetail(this.movieId).then((result) => {
          this.movieDetail = result;
          this.playerInstanceFactory(this.movieDetail);
        });

        // workaround for videojs not being to reinitialize in vue, so we just specify a new player
        // (see https://github.com/videojs/video.js/issues/4865)
        this.epoch = new Date().getTime();
      }
    },
    playerInstanceFactory(movieData) {
      // even if they're a member, we want to show the sample movie for the video modal, so we're
      // faking a guest user here
      const userData = {
        typeID: 0,
        typeStr: 'NonMember',
        isSiteMember: false,
        isTrial: false,
        isRecurring: false,
        isAnnual: false,
      };
      PlayerFactory.register('VideoJS', () => new VideoJS(`sample-video-${this.epoch}`, movieData, userData));

      const init = (Factory) => {
        this.videoPlayerInstance = Factory.create('VideoJS');
        // player class methods can be accessed here.
        // example (assuming ack() method in the JWPlayer.js class): videoPlayerInstance.ack('hello');
      };
      setTimeout(() => { init(PlayerFactory); }, 100);
    },
    closeModal() {
      this.$modal.hide('ui-modal-video');
      this.videoPlayerInstance.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.v--modal-overlay {
  /* override default with less alpha transparency */
  background: rgba(0, 0, 0, 0.8);
}

.v--modal-override {
  /* override modal background color from #fff to #000 */
  /* this css class is not loading for some reason */
  background-color: #000;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
  padding: 0;
}
</style>
