<i18n>
{
  "en": {
    "latest_movies": "Latest Movies",
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming",
    "more_movies": "View More"
  },
  "ja": {
    "latest_movies": "最新作",
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信",
    "more_movies": "もっと見る"
  }
}
</i18n>

<template>
<div class="container">
  <div class="section">
    <h2 class="is-primary">{{ $t('latest_movies') }}</h2>

    <div class="grid is-movie is-card">
      <div v-for="(movie, index) in movieList" :key="movie.MovieID" class="grid-item">
        <div class="entry">
          <div class="entry-media is-play" @click="showModalSample(movie.MovieID);">
            <div v-if="!newXPEligible && movie.isVIP" class="tag-member is-vip">{{ $t('vip_badge') }}</div>
            <div v-if="!newXPEligible && movie.isSVIP" class="tag-member is-svip">{{ $t('svip_badge') }}</div>
            <div v-if="!newXPEligible && movie.isAnnual" class="tag-member is-annual">{{ $t('annual_badge') }}</div>
            <div class="media-thum">
              <img class="media-image" :src="`/assets/sample/${movie.MovieID}/${moviePoster}`" @error="useFallbackImage(index)" loading="lazy" alt="" v-sfw>
            </div>
          </div>
          <router-link
            :to="`/movies/${movie.MovieID}/`"
            :data-movie-id="movie.MovieID"
            :data-movie-title="movie.Title"
            :data-actress-name="movie.Actor"
            class="entry-meta">
            <div v-if="movie.IsLimited" class="tag-status is-limited">{{ $t('limited_tag') }}</div>
            <div v-if="movie.IsExpired" class="tag-status is-expired">{{ $t('expired_tag') }}</div>
            <div v-if="movie.IsComingSoon" class="tag-status is-future">{{ $t('future_tag') }}</div>
            <div class="meta-data">{{ movie.Release }}</div>
            <div v-if="locale == 'ja'">
              <div class="meta-title">{{ movie.Title }}</div>
              <div class="meta-data" v-if="locale == 'ja'"><span v-for="(actress, index) in movie.ActressesJa" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesJa.length">&#32;&#32;</span></span></div>
            </div>
            <div v-if="locale == 'en'">
              <div v-if="movie.TitleEn != null && movie.TitleEn != ''" class="meta-title">{{ movie.TitleEn }}</div>
              <div class="meta-data" v-if="locale == 'en'"><span v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesEn.length">,&#32;</span></span></div>
            </div>
            <!-- age -->
            <age-tag :movie="movie" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */

import { prefetch } from 'quicklink';
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import AgeTag from '@/components/_common/ageTag.vue';

const MOVIE_POSTER_FILENAME = 'l_hd.jpg';

export default {
  props: {
    numMovies: Number,
  },
  components: {
    'age-tag': AgeTag,
  },
  data() {
    return {
      moviePoster: MOVIE_POSTER_FILENAME,
      movieList: [],
    };
  },
  created() {
    this.getMovies(this.userTypeID);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    async getMovies(userTypeID) {
      // fetch newest movies
      const bfAPI = new BifrostAPI();
      const result = await bfAPI.getMovieListNewest(0, parseInt(this.numMovies, 10));
      const movieList = MovieHelper.expandDetailsList(result.Rows, this.locale, userTypeID);
      this.movieList = MovieHelper.filterExcludedMovieList(movieList, userTypeID);

      // check actress age
      // hide age if there are more than 1 actress in actressList
      // only display age if it's set over '18' (prevent webmaster input error and ignore data of age'0' in db)
      for (let i = 0; i < this.movieList.length; i += 1) {
        if (this.movieList[i].ActressesList && Object.keys(this.movieList[i].ActressesList).length === 1) {
          const firstActressID = Object.keys(this.movieList[i].ActressesList)[0];
          if (this.movieList[i].ActressesList[firstActressID].Age
              && this.movieList[i].ActressesList[firstActressID].Age >= 18) {
            this.$set(this.movieList[i], 'actressAge', this.movieList[i].ActressesList[firstActressID].Age);
          }
        }
      }

      // prefetch movie detail jsons and posters
      this.prefetchMovies();
    },

    prefetchMovies() {
      // using vue-mobile-detection lib, this.$isMobile() returns a Boolean value whether or not the user is using a mobile
      const prefetchedMovieList = (this.$isMobile()) ? this.movieList.slice(0, 1) : this.movieList.slice(0, 3);
      for (let i = 0; i < prefetchedMovieList.length; i += 1) {
        // movie detail jsons
        prefetch(`/dyn/phpauto/movie_details/movie_id/${prefetchedMovieList[i].MovieID}.json`);
        // movie posters
        prefetch(`/assets/sample/${prefetchedMovieList[i].MovieID}/${this.moviePoster}`);
      }
    },

    showActressAge(movieActressesList) {
      let showAge = false;
      if (movieActressesList && Object.keys(movieActressesList).length === 1) {
        // except ignore actresses with an age of 0 in the db; actually setting to 18 so in case of
        // a webmaster typo and puts '8' instead of '18', we don't end up advertising a movie for an
        // 8 year old
        const firstActressID = Object.keys(movieActressesList)[0];
        if (movieActressesList[firstActressID].Age
            && movieActressesList[firstActressID].Age >= 18) showAge = true;
      }
      return showAge;
    },

    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to "no image" image. since this is a "newest
      // movies" list, we can guarantee that if the thumbnail poster is missing, then this movie
      // isn't quite ready yet
      this.$set(this.movieList[index], 'thumbnailSrc', '/img/common/now_printing.png');
    },

    showModalSample(movieId) {
      this.$modal.show('ui-modal-video', { movieId });
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovies(newVal);
    },
    numMovies(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovies(this.userTypeID);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
