<template>
  <div id="page">
    <!-- inline svg (sourced from mockups: /images/dl-icons.svg) -->
    <svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="dl-icon-add" viewBox="0 0 32 32">
          <path d="M25.333 17.333h-8v8h-2.667v-8h-8v-2.667h8v-8h2.667v8h8v2.667z"></path>
        </symbol>
        <symbol id="dl-icon-app" viewBox="0 0 32 32">
          <path d="M5.334 10.667h5.333v-5.333h-5.333v5.333zM13.334 26.667h5.333v-5.333h-5.333v5.333zM5.334 26.667h5.333v-5.333h-5.333v5.333zM5.334 18.667h5.333v-5.333h-5.333v5.333zM13.334 18.667h5.333v-5.333h-5.333v5.333zM21.334 5.334v5.333h5.333v-5.333h-5.333zM13.334 10.667h5.333v-5.333h-5.333v5.333zM21.334 18.667h5.333v-5.333h-5.333v5.333zM21.334 26.667h5.333v-5.333h-5.333v5.333z"></path>
        </symbol>
        <symbol id="dl-icon-arrow-drop-down" viewBox="0 0 32 32">
          <path d="M9.333 13.333l6.667 6.667 6.667-6.667h-13.333z"></path>
        </symbol>
        <symbol id="dl-icon-arrow-drop-up" viewBox="0 0 32 32">
          <path d="M9.333 18.667l6.667-6.667 6.667 6.667h-13.333z"></path>
        </symbol>
        <symbol id="dl-icon-arrow-left" viewBox="0 0 32 32">
          <path d="M16 5.333l1.88 1.88-7.44 7.453h16.227v2.667h-16.227l7.44 7.453-1.88 1.88-10.667-10.667 10.667-10.667z"></path>
        </symbol>
        <symbol id="dl-icon-arrow-right" viewBox="0 0 32 32">
          <path d="M16 5.333l-1.88 1.88 7.44 7.453h-16.227v2.667h16.227l-7.44 7.453 1.88 1.88 10.667-10.667-10.667-10.667z"></path>
        </symbol>
        <symbol id="dl-icon-bookmark" viewBox="0 0 32 32">
          <path d="M22.667 2.667h-13.333c-1.467 0-2.653 1.2-2.653 2.667l-0.013 24 9.333-4 9.333 4v-24c0-1.467-1.201-2.667-2.667-2.667zM21.333 16h-4v4h-2.667v-4h-4v-2.667h4v-4h2.667v4h4v2.667z"></path>
        </symbol>
        <symbol id="dl-icon-cancel" viewBox="0 0 32 32">
          <path d="M16 2.667c-7.361 0-13.333 5.972-13.333 13.333 0 7.366 5.972 13.333 13.333 13.333 7.366 0 13.333-5.967 13.333-13.333 0-7.361-5.967-13.333-13.333-13.333zM22.943 21.057l-1.885 1.885-5.057-5.057-5.057 5.057-1.885-1.885 5.057-5.057-5.057-5.057 1.886-1.886 5.057 5.057 5.057-5.057 1.885 1.885-5.057 5.057 5.057 5.057z"></path>
        </symbol>
        <symbol id="dl-icon-cart" viewBox="0 0 32 32">
          <path d="M25.333 26.667c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667z"></path>
          <path d="M12 26.667c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667z"></path>
          <path d="M6.953 8l-0.727-4h-4.892v2.667h2.667l2.666 14.667h18.667l2.424-13.333z"></path>
        </symbol>
        <symbol id="dl-icon-cart-add" viewBox="0 0 32 32">
          <path d="M14.667 14.667h2.667v-4h4v-2.667h-4v-4h-2.667v4h-4v2.667h4z"></path>
          <path d="M25.333 26.667c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667z"></path>
          <path d="M12 26.667c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667z"></path>
          <path d="M25.047 8l-1.939 10.667h-14.215l-2.667-14.667h-4.892v2.667h2.667l2.666 14.667h18.667l2.424-13.333z"></path>
        </symbol>
        <symbol id="dl-icon-cart-outline" viewBox="0 0 32 32">
          <path d="M25.333 26.667c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667z"></path>
          <path d="M12 26.667c0 1.473-1.194 2.667-2.667 2.667s-2.667-1.194-2.667-2.667c0-1.473 1.194-2.667 2.667-2.667s2.667 1.194 2.667 2.667z"></path>
          <path d="M25.047 8l-1.939 10.667h-14.215l-2.667-14.667h-4.892v2.667h2.667l2.666 14.667h18.667l2.424-13.333z"></path>
        </symbol>
        <symbol id="dl-icon-cc" viewBox="0 0 32 32">
          <path d="M26.667 6.667h-21.333c-1.467 0-2.667 1.2-2.667 2.667v13.333c0 1.466 1.2 2.667 2.667 2.667h21.333c1.466 0 2.667-1.201 2.667-2.667v-13.333c0-1.467-1.201-2.667-2.667-2.667zM13.333 20v-8l6.667 4-6.667 4z"></path>
        </symbol>
        <symbol id="dl-icon-check" viewBox="0 0 32 32">
          <path d="M28 9.333l-14.667 14.667-8-8 2-2 6 6 12.667-12.667z"></path>
        </symbol>
        <symbol id="dl-icon-check-box" viewBox="0 0 32 32">
          <path d="M25.333 4h-18.667c-1.48 0-2.667 1.2-2.667 2.667v18.667c0 1.466 1.187 2.667 2.667 2.667h18.667c1.479 0 2.667-1.201 2.667-2.667v-18.667c0-1.467-1.187-2.667-2.667-2.667zM13.333 22.667l-6.667-6.667 2-2 4.667 4.667 10-10 2 2-12 12z"></path>
        </symbol>
        <symbol id="dl-icon-circle-arrow-left" viewBox="0 0 32 32">
          <path d="M2.667 16c0 7.365 5.969 13.333 13.333 13.333s13.333-5.969 13.333-13.333c0-7.365-5.969-13.333-13.333-13.333s-13.333 5.969-13.333 13.333zM8 16l8-8 1.885 1.885-4.781 4.781h10.896v2.667h-10.896l4.781 4.781-1.885 1.885-8-8z"></path>
        </symbol>
        <symbol id="dl-icon-circle-arrow-right" viewBox="0 0 32 32">
          <path d="M16 2.667c-7.365 0-13.333 5.969-13.333 13.333s5.969 13.333 13.333 13.333c7.365 0 13.333-5.969 13.333-13.333s-5.969-13.333-13.333-13.333zM16 24l-1.885-1.885 4.781-4.781h-10.896v-2.667h10.896l-4.781-4.781 1.885-1.885 8 8-8 8z"></path>
        </symbol>
        <symbol id="dl-icon-clear" viewBox="0 0 32 32">
          <path d="M17.885 16l7.057-7.057-1.885-1.885-7.057 7.057-7.057-7.057-1.886 1.886 7.057 7.057-7.057 7.057 1.885 1.885 7.057-7.057 7.057 7.057 1.885-1.885z"></path>
        </symbol>
        <symbol id="dl-icon-cloud" viewBox="0 0 32 32">
          <path d="M25.799 13.387c-0.906-4.6-4.945-8.053-9.799-8.053-3.853 0-7.2 2.187-8.867 5.387-4.013 0.427-7.133 3.827-7.133 7.947 0 4.414 3.587 8 8 8h17.333c3.68 0 6.667-2.987 6.667-6.667 0-3.52-2.733-6.373-6.201-6.613zM18.667 17.333v5.333h-5.333v-5.333h-4l6.667-6.667 6.667 6.667h-4z"></path>
        </symbol>
        <symbol id="dl-icon-crown" viewBox="0 0 32 32">
          <path d="M22.667 12l-6.666-8-6.667 8-6.667-2.667 4 16h18.667l4-16z"></path>
        </symbol>
        <symbol id="dl-icon-download" viewBox="0 0 32 32">
          <path d="M25.333 12h-5.333v-8h-8v8h-5.333l9.333 9.333 9.333-9.333zM6.667 24v2.667h18.667v-2.667h-18.667z"></path>
        </symbol>
        <symbol id="dl-icon-dx" viewBox="0 0 32 32">
          <path d="M29.333 14h-4.158l-4.158 3.725-1.986-2.203c0.208-0.687 0.323-1.349 0.323-2.104 0-4.135-3.353-7.418-7.485-7.418h-9.202v15.333h9.202c2.278 0 4.316-1.38 5.689-2.985l1.381 1.73-6.238 6.588h4.159l4.158-4.158 4.159 4.158h4.158l-6.237-6.333 6.237-6.333zM11.869 18h-5.869v-8.667h5.869c2.295 0 4.159 2.667 4.159 4.667h-3.327l2.485 2.405c-0.759 1.012-1.957 1.595-3.317 1.595z"></path>
        </symbol>
        <symbol id="dl-icon-ex-link" viewBox="0 0 32 32">
          <path d="M22.667 25.333h-16v-16h9.333v-2.667h-9.333c-1.48 0-2.667 1.2-2.667 2.667v16c0 1.467 1.186 2.667 2.667 2.667h16c1.466 0 2.667-1.2 2.667-2.667v-9.333h-2.667v9.333z"></path>
          <path d="M18.667 4v2.667h4.787l-11.060 11.060 1.88 1.88 11.060-11.060v4.787h2.667v-9.333z"></path>
        </symbol>
        <symbol id="dl-icon-filter" viewBox="0 0 32 32">
          <path d="M13.333 24h5.333v-2.667h-5.333v2.667zM4 8v2.667h24v-2.667h-24zM8 17.333h16v-2.667h-16v2.667z"></path>
        </symbol>
        <symbol id="dl-icon-help" viewBox="0 0 32 32">
          <path d="M14.667 24h2.667v-2.667h-2.667v2.667zM16 2.667c-7.359 0-13.333 5.974-13.333 13.333s5.974 13.333 13.333 13.333 13.333-5.974 13.333-13.333-5.974-13.333-13.333-13.333zM16 26.667c-5.88 0-10.667-4.787-10.667-10.667s4.787-10.667 10.667-10.667 10.667 4.787 10.667 10.667-4.787 10.667-10.667 10.667zM16 8c-2.947 0-5.333 2.387-5.333 5.333h2.667c0-1.466 1.201-2.667 2.667-2.667s2.667 1.201 2.667 2.667c0 2.667-4 2.333-4 6.667h2.667c0-3 4-3.333 4-6.667 0-2.947-2.387-5.333-5.333-5.333z"></path>
        </symbol>
        <symbol id="dl-icon-home" viewBox="0 0 32 32">
          <path d="M13.333 26.667v-8h5.333v8h6.667v-10.667h4l-13.333-12-13.333 12h4v10.667h6.667z"></path>
        </symbol>
        <symbol id="dl-icon-keyboard-arrow-down" viewBox="0 0 32 32">
          <path d="M9.88 11.453l6.12 6.107 6.12-6.107 1.88 1.88-8 8-8-8 1.88-1.88z"></path>
        </symbol>
        <symbol id="dl-icon-keyboard-arrow-l-left" viewBox="0 0 32 32">
          <path d="M21.333 2.667l1.885 1.885-11.448 11.448 11.448 11.448-1.885 1.885-13.333-13.333z"></path>
        </symbol>
        <symbol id="dl-icon-keyboard-arrow-l-right" viewBox="0 0 32 32">
          <path d="M10.667 2.667l-1.885 1.885 11.448 11.448-11.448 11.448 1.885 1.885 13.333-13.333z"></path>
        </symbol>
        <symbol id="dl-icon-keyboard-arrow-left" viewBox="0 0 32 32">
          <path d="M20.547 22.12l-6.107-6.12 6.107-6.12-1.88-1.88-8 8 8 8 1.88-1.88z"></path>
        </symbol>
        <symbol id="dl-icon-keyboard-arrow-right" viewBox="0 0 32 32">
          <path d="M11.453 22.12l6.107-6.12-6.107-6.12 1.88-1.88 8 8-8 8-1.88-1.88z"></path>
        </symbol>
        <symbol id="dl-icon-keyboard-arrow-up" viewBox="0 0 32 32">
          <path d="M22.12 20.547l-6.12-6.107-6.12 6.107-1.88-1.88 8-8 8 8-1.88 1.88z"></path>
        </symbol>
        <symbol id="dl-icon-menu" viewBox="0 0 32 32">
          <path d="M4 24h24v-2.667h-24v2.667zM4 17.333h24v-2.667h-24v2.667zM4 8v2.667h24v-2.667h-24z"></path>
        </symbol>
        <symbol id="dl-icon-more-horiz" viewBox="0 0 32 32">
          <path d="M8 13.333c-1.467 0-2.667 1.2-2.667 2.667s1.2 2.667 2.667 2.667c1.466 0 2.667-1.201 2.667-2.667s-1.201-2.667-2.667-2.667zM24 13.333c-1.466 0-2.667 1.2-2.667 2.667s1.201 2.667 2.667 2.667 2.667-1.201 2.667-2.667c0-1.467-1.201-2.667-2.667-2.667zM16 13.333c-1.466 0-2.667 1.2-2.667 2.667s1.201 2.667 2.667 2.667 2.667-1.201 2.667-2.667c0-1.467-1.201-2.667-2.667-2.667z"></path>
        </symbol>
        <symbol id="dl-icon-more-vert" viewBox="0 0 32 32">
          <path d="M16 10.667c1.466 0 2.667-1.2 2.667-2.667s-1.201-2.667-2.667-2.667-2.667 1.2-2.667 2.667 1.201 2.667 2.667 2.667zM16 13.333c-1.466 0-2.667 1.2-2.667 2.667s1.201 2.667 2.667 2.667 2.667-1.201 2.667-2.667c0-1.467-1.201-2.667-2.667-2.667zM16 21.333c-1.466 0-2.667 1.201-2.667 2.667s1.201 2.667 2.667 2.667 2.667-1.201 2.667-2.667-1.201-2.667-2.667-2.667z"></path>
        </symbol>
        <symbol id="dl-icon-person" viewBox="0 0 32 32">
          <path d="M21.333 10.667c0 2.945-2.391 5.333-5.333 5.333-2.945 0-5.333-2.388-5.333-5.333s2.388-5.333 5.333-5.333c2.943 0 5.333 2.388 5.333 5.333z"></path>
          <path d="M26.667 26.667c0-5.893-4.773-9.333-10.667-9.333s-10.667 3.44-10.667 9.333h21.333z"></path>
        </symbol>
        <symbol id="dl-icon-person-circle" viewBox="0 0 32 32">
          <path d="M16 2.667c-7.36 0-13.333 5.973-13.333 13.333s5.973 13.333 13.333 13.333 13.333-5.973 13.333-13.333-5.973-13.333-13.333-13.333zM16 6c2.575 0 4.667 2.089 4.667 4.667s-2.092 4.667-4.667 4.667c-2.577 0-4.667-2.089-4.667-4.667s2.089-4.667 4.667-4.667zM16 27.333c-3.986 0-7.491-2.075-9.513-5.197 1.759-3.082 5.352-4.803 9.513-4.803s7.755 1.721 9.513 4.804c-2.022 3.121-5.527 5.196-9.513 5.196z"></path>
        </symbol>
        <symbol id="dl-icon-phone" viewBox="0 0 32 32">
          <path d="M21.333 1.333h-10.667c-2.213 0-4 1.787-4 4v21.333c0 2.213 1.787 4 4 4h10.667c2.213 0 4-1.787 4-4v-21.333c0-2.213-1.787-4-4-4zM18.667 28h-5.333v-1.333h5.333v1.333zM22.667 24h-13.333v-18.667h13.333v18.667z"></path>
        </symbol>
        <symbol id="dl-icon-photo" viewBox="0 0 32 32">
          <path d="M25.333 4h-18.667c-1.467 0-2.667 1.2-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667v-18.667c0-1.467-1.2-2.667-2.667-2.667zM25.333 25.333h-18.667v-18.667h18.667v18.667z"></path>
          <path d="M6.667 21.333l4-3.333 4 3.333 6.667-6.667 4 4v6.667h-18.667z"></path>
        </symbol>
        <symbol id="dl-icon-play" viewBox="0 0 32 32">
          <path d="M13.333 21.333l8-5.333-8-5.333z"></path>
          <path d="M16 2.667c-7.36 0-13.333 5.973-13.333 13.333s5.973 13.333 13.333 13.333 13.333-5.973 13.333-13.333-5.973-13.333-13.333-13.333zM16 27.333c-6.247 0-11.333-5.086-11.333-11.333s5.086-11.333 11.333-11.333c6.247 0 11.333 5.086 11.333 11.333s-5.086 11.333-11.333 11.333z"></path>
        </symbol>
        <symbol id="dl-icon-play-fill" viewBox="0 0 32 32">
          <path d="M16 2.667c-7.36 0-13.333 5.973-13.333 13.333s5.973 13.333 13.333 13.333 13.333-5.973 13.333-13.333-5.973-13.333-13.333-13.333zM13.333 22v-12l8 6-8 6z"></path>
        </symbol>
        <symbol id="dl-icon-ppv" viewBox="0 0 32 32">
          <path d="M6.841 10.903h-4.841v10.194h2.655v-3.397h2.185c2.241 0 3.686-0.985 3.686-3.514 0-2.411-1.787-3.283-3.686-3.283zM6.441 15.645h-1.786v-2.613h1.786c0.812 0 1.427 0.372 1.427 1.342 0 0.929-0.586 1.271-1.427 1.271zM16.531 10.903h-4.84v10.194h2.654v-3.397h2.185c2.238 0 3.683-0.985 3.683-3.514 0.001-2.411-1.783-3.283-3.682-3.283zM16.132 15.645h-1.787v-2.613h1.787c0.814 0 1.426 0.372 1.426 1.342 0 0.929-0.584 1.271-1.426 1.271zM27.232 10.903l-1.997 7.581h-0.029l-2.005-7.581h-2.768l3.216 10.194h3.051l3.301-10.194h-2.768z"></path>
        </symbol>
        <symbol id="dl-icon-remove" viewBox="0 0 32 32">
          <path d="M25.333 17.333h-18.667v-2.667h18.667v2.667z"></path>
        </symbol>
        <symbol id="dl-icon-search" viewBox="0 0 32 32">
          <path d="M26.943 25.057l-5.951-5.951c1.049-1.433 1.675-3.194 1.675-5.106 0-4.787-3.88-8.667-8.667-8.667s-8.667 3.88-8.667 8.667 3.88 8.667 8.667 8.667c1.912 0 3.673-0.626 5.106-1.675l5.951 5.951 1.885-1.885zM14 20c-3.313 0-6-2.687-6-6s2.687-6 6-6c3.313 0 6 2.687 6 6s-2.687 6-6 6z"></path>
        </symbol>
        <symbol id="dl-icon-share" viewBox="0 0 32 32">
          <path d="M25.333 24h-20v-16h6.667v-2.667h-6.667c-1.48 0-2.667 1.2-2.667 2.667v16c0 1.467 1.186 2.667 2.667 2.667h20c1.466 0 2.667-1.2 2.667-2.667v-4h-2.667v4z"></path>
          <path d="M30.667 10.667l-8.667-6.667v4c-6.62 0.659-12.353 5.483-13.333 12 3.289-4.487 7.529-6.667 13.333-6.667v4l8.667-6.667z"></path>
        </symbol>
      </defs>
    </svg>

    <!-- app layout -->
    <AppHeader />

    <router-view :key="$route.fullPath" />
    <AppFooter />

    <!-- app shell components (modals, notifications) -->
    <vue-progress-bar></vue-progress-bar>
    <ModalAgeCheck exit-url="https://www.yahoo.com" english-url="https://en.pacopacomama.com" />
    <ModalLogin />
    <ModalSQA />
    <ModalVideo />
    <NoticeMaintenance />
    <NoticeEvents />
    <TrialBanner />
  </div>
</template>

<script>
/* eslint max-len: 0 */
// important note: now playing Orbital/"The Girl with the Sun in Her Head" (in case i need to get
// into the same mindset as when this was being written 🎧

// reupy stuff
import Cookies from 'js-cookie';
import APP_CONFIG from './appConfig';
import BifrostAPI from './assets/js/services/Bifrost/API';
import D2PToolbar from './assets/js/services/D2PToolbar';
import Cache from './assets/js/utils/Cache';
import Analytics from './assets/js/utils/Analytics';

// app shell components
import AppHeader from './components/_shell/header/index.vue';
import AppFooter from './components/_shell/footer/index.vue';
import ModalAgeCheck from './components/_shell/modal/ageCheck.vue';
import ModalLogin from './components/_shell/modal/login.vue';
import ModalSQA from './components/_shell/modal/SQA.vue';
import ModalVideo from './components/_shell/modal/video.vue';
import NoticeMaintenance from './components/_shell/notification/maintenance.vue';
import NoticeEvents from './components/_shell/notification/events.vue';
import TrialBanner from './components/_shell/notification/trialBanner.vue';

// init D2P Toolbar
const D2PToolbarSvc = new D2PToolbar();
const bfAPI = new BifrostAPI();

export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter,
    ModalAgeCheck,
    ModalLogin,
    ModalSQA,
    ModalVideo,
    NoticeMaintenance,
    NoticeEvents,
    TrialBanner,
  },
  data() {
    return {};
  },
  mounted() {
    // clear localforage caches on app startup
    const cacheInstances = ['bifrost', 'siteConfig', 'vrack'];
    cacheInstances.forEach((name) => {
      const cache = new Cache(name);
      cache.clear();
    });

    this.$router.onReady(() => {
      // save 's2s_id' parameter (to send back to join when purchasing) on initial hit to site
      // ref: http://redmine.ops/issues/8562
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 's2s_id')) {
        this.$store.dispatch('purchaseCodes/setS2S', this.$route.query.s2s_id);
      }
      // also save 'utm_source' and 'utm_medium' query params
      // ref: https://giganik.atlassian.net/browse/DLIT-120
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'utm_source')) {
        this.$store.dispatch('purchaseCodes/setUTMSource', this.$route.query.utm_source);
      }
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'utm_medium')) {
        this.$store.dispatch('purchaseCodes/setUTMMedium', this.$route.query.utm_medium);
      }

      // get 'promo' cookie, parse and save 'affi_promo' code (to send back to join when purchasing)
      // 'promo' cookie structure: 'promo=103=2SJ7D2VR-9103063-61422.1606167864'
      // 'affi_promo' code = 2SJ7D2VR
      // ref: http://redmine.ops/issues/10477
      if (typeof Cookies.get('promo') !== 'undefined') {
        const cookieArr = Cookies.get('promo').split('='); // cookieArr: ['promo', '103', '2SJ7D2VR-9103063-61422.1606167864']
        const promoArr = cookieArr[2].split('-'); // promoArr: ['2SJ7D2VR', '9103063', '61422.1606167864']
        this.$store.dispatch('purchaseCodes/setAffiPromo', promoArr[0]);
      }

      // save 'sfw' parameter to the prefs store
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'sfw')) {
        this.$store.dispatch('prefs/setSfwMode', this.$route.query.sfw);
      }

      // redirect to en site if 1st hit contains ?lang=en param
      Object.keys(APP_CONFIG.site.engUrls).forEach((hostname) => {
        if (
          this.$route.query.lang === 'en'
            && window.location.hostname.match(hostname)
        ) {
          window.location = APP_CONFIG.site.engUrls[hostname];
        }
      });

      // send 1st hit to Neti log
      bfAPI.logToNeti('ROUTE_LOADED', {
        from: undefined,
        to: this.$route.fullPath,
      });
    });
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
  computed: {
    waitingCount() {
      return this.$store.getters['bifrost/waitingCount'];
    },
  },
  watch: {
    // do things on each route change
    $route(to, from) {
      // refresh toolbar on route change for non-site-members only
      const isSiteMember = this.$store.getters['user/isSiteMember'];
      if (!isSiteMember && to !== from) D2PToolbarSvc.refresh();

      // log all route changes to Neti
      bfAPI.logToNeti('ROUTE_LOADED', { from: from.fullPath, to: to.fullPath });

      // send page view to GA4/GTM (with a short delay to prevent blank url/titles)
      setTimeout(() => {
        Analytics.sendPageView();
      }, 1500);
    },
    // watch waitingCount from store and start the progressbar when there are pending requests,
    // and stop it when the number of pending requests goes back to 0
    waitingCount(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (oldVal === 0 && newVal >= 1) {
          this.$Progress.start();
        } else if (oldVal >= 1 && newVal >= 1) {
          this.$Progress.increase(10);
        } else if (oldVal >= 1 && newVal === 0) {
          this.$Progress.finish();
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/main';

body {
  height: 100%;
  #app {
    height: 100%;
  }
}
</style>
