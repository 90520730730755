
<i18n>
{
  "en": {
    "sqa_setup_description": "Please choose the secret question, and set up the answer.",
    "sqa_setup_description_sub": "* You can use alphabets and numbers.",
    "sqa_answer_description": "To login, please answer the secret question.",
    "sqa_title_question": "Question:",
    "sqa_setup_question_1": "Last 4 digits of your phone number",
    "sqa_setup_question_2": "Name of your first pet",
    "sqa_setup_question_3": "Model of your first car",
    "sqa_setup_question_4": "Year of graduation from high school",
    "sqa_setup_question_5": "Other",
    "sqa_title_answer": "Answer:",
    "sqa_submit_answer": "Login",
    "sqa_submit_setup": "Setup & Login",
    "sqa_reset_description": "Reset your secret question and answer from ",
    "sqa_reset_link": "here »",
    "about_sqa": "About secret question and answer »",
    "failed_validation": "Please enter the correct answer."
  },
  "ja": {
    "sqa_setup_description": "セキュリティ強化のため、秘密の質問と答えを設定してください。",
    "sqa_setup_description_sub": "※全角ひらがな、カタカナ、漢字、半角英数字が使用できます。",
    "sqa_answer_description": "ご本人確認として、秘密の質問にご回答し、ログインしてください。",
    "sqa_title_question": "質問:",
    "sqa_setup_question_1": "電話番号の下4桁",
    "sqa_setup_question_2": "初めて飼ったペットの名前",
    "sqa_setup_question_3": "初めて買った車の車種",
    "sqa_setup_question_4": "高校卒業の年度",
    "sqa_setup_question_5": "その他",
    "sqa_title_answer": "答え:",
    "sqa_submit_answer": "ログインする",
    "sqa_submit_setup": "設定してログインする",
    "sqa_reset_description": "秘密の質問の再設定は",
    "sqa_reset_link": "こちら »",
    "about_sqa": "「秘密の質問と答え」について »",
    "failed_validation": "正しい答えを入力してください。"
  }
}
</i18n>

<template>
  <modal class="dl-modal dl-modal--login" name="ui-modal-sqa" :draggable="false" :clickToClose="false" @before-close="beforeClose">
    <!-- SQA setup -->
    <div class="login sqa-setup" v-if="showSetupModal">
      <div class="login-section--brand">
        <img class="login-logo" src="/img/common/paco-logo-lg.svg" alt="">
      </div>
      <div class="login-section--sqa">{{ $t('sqa_setup_description') }}<span class="login-sqa-note">{{ $t('sqa_setup_description_sub') }}</span></div>
      <form v-on:submit.prevent name="login-form" class="login-section--form">
        <div class="login-form-title">
          {{ $t('sqa_title_question') }}
          <select v-model="questionSelected" @change="copyQuestionToInput()" name="sqa-question" class="login-sqa-dropdown">
            <option :value="$t('sqa_setup_question_1')">{{ $t('sqa_setup_question_1') }}</option>
            <option :value="$t('sqa_setup_question_2')">{{ $t('sqa_setup_question_2') }}</option>
            <option :value="$t('sqa_setup_question_3')">{{ $t('sqa_setup_question_3') }}</option>
            <option :value="$t('sqa_setup_question_4')">{{ $t('sqa_setup_question_4') }}</option>
            <option value="custom_question">{{ $t('sqa_setup_question_5') }}</option>
          </select>
        </div>
        <input type="text" v-if="questionSelected === 'custom_question'" v-model="inputQuestion" class="login-form-input">
        <div class="login-form-title">{{ $t('sqa_title_answer') }}</div>
        <input type="text" v-model="inputAnswer" class="login-form-input">
      </form>
      <div class="login-section--cta">
        <button class="button-sqa-setup button is-filled is-large is-rounded" :class="{'is-disabled': !inputQuestion || !inputAnswer}" @click="submitSetup()">{{ $t('sqa_submit_setup') }}</button>
        <div v-if="hasError" class="error">{{ $t('failed_validation') }}</div>
      </div>
    </div>
    <!-- /SQA setup -->

    <!-- SQA verification -->
    <div class="login sqa-answer" v-if="showAnswerModal">
      <div class="login-section--brand">
        <img class="login-logo" src="/img/common/paco-logo-lg.svg" alt="">
      </div>
      <div class="login-section--sqa">{{ $t('sqa_answer_description') }}</div>
      <form v-on:submit.prevent name="login-form" class="login-section--form">
        <div class="login-form-title">{{ $t('sqa_title_question') }}<span class="login-form-title-sub">{{ SQA.question }}</span></div>
        <div class="login-form-title">{{ $t('sqa_title_answer') }}<span class="login-form-title-error">{{ $t('failed_validation') }}</span></div>
        <input type="text" v-model="inputAnswer" @keypress.enter="submitAnswer()" class="login-form-input">
      </form>
      <div class="login-section--cta">
        <button class="button-sqa-setup button is-filled is-large is-rounded" :class="{'is-disabled': !inputAnswer}" @click="submitAnswer()">{{ $t('sqa_submit_answer') }}</button>
        <div v-if="hasError" class="error">{{ $t('failed_validation') }}</div>
      </div>
      <div class="sqa-extra">{{ $t('sqa_reset_description') }}<a href="https://sqa.d2pass.com/sqa/edit" target="_blank" rel="noopener">{{ $t('sqa_reset_link') }}</a></div>
    </div>
    <!-- /SQA verification -->

    <!-- SQA lockout message -->
    <div class="login sqa-answer" v-if="showLockoutModal">
      <div class="login-section--brand">
        <img class="login-logo" src="/img/common/paco-logo-lg.svg" alt="">
      </div>
      <div class="login-section--sqa"><span v-html="SQA.lockoutMsg"></span></div>
    </div>
    <!-- /SQA lockout message -->
  </modal>
</template>

<script>
/* eslint max-len: 0 */
import axios from 'axios';
import Cookies from 'js-cookie';
import EventBus from '@/assets/js/utils/EventBus';
import User from '@/assets/js/services/User';
import APP_CONFIG from '@/appConfig';

export default {
  data() {
    return {
      SQA: {
        id: null,
        question: '',
        lockoutMsg: '',
      },
      questionSelected: this.$t('sqa_setup_question_1'), // default in the dropdown is the 1st question
      inputQuestion: '',
      inputAnswer: '',
      showSetupModal: false,
      showAnswerModal: false,
      showLockoutModal: false,
      hasError: false,
    };
  },
  mounted() {
    // the 1st question is the default, so we set the question input value to this when the
    // component loads (we also set the input question whenever the select dropdown changes)
    this.inputQuestion = (this.questionSelected !== 'custom_question') ? this.questionSelected : '';

    EventBus.$on('SQA:triggered', () => {
      setTimeout(() => {
        // get SQA question from D2 API
        if (this.UID) {
          axios.get(`https://api.d2pass.com/v1/users/${this.UID}/secret_question`).then((response) => {
            if (response.data !== null) {
              this.SQA.id = response.data.data.id;
              this.SQA.question = response.data.data.attributes.question;
              this.showAnswerModal = true;
            } else {
              // null response, do SQA setup
              this.showSetupModal = true;
            }
            // show SQA modal
            this.$modal.show('ui-modal-sqa');
          }).catch((error) => {
            console.log('%cerror getting from API', 'background: #222; color: #f00', error);
          });
        }
      }, 3000);
    });
  },
  methods: {
    copyQuestionToInput() {
      // when we submit, we POST the value of the question input textbox. for pre-selected questions
      // we set the input box value to the value of the selected question here
      this.inputQuestion = (this.questionSelected !== 'custom_question') ? this.questionSelected : '';
    },
    submitSetup() {
      // post user's Q&A inputs to API
      axios.post(`https://api.d2pass.com/v1/users/${this.UID}/secret_question`, {
        sqa: {
          question: this.inputQuestion,
          answer: this.inputAnswer,
          site_id: APP_CONFIG.site.d2pSiteId,
        },
      }).then((response) => {
        // success
        if (response.status === 201) {
          this.$modal.hide('ui-modal-sqa');

          // let's not annoy the user and do another prompt after SQA setup. so set a session
          // so that the user won't get the SQA verification immediately after
          Cookies.set('reupSQA', 1, { domain: APP_CONFIG.site.domain });
        }
      }).catch((error) => {
        // fail
        if (error.response.status === 422) {
          this.hasError = true;
          // reset input question textbox
          this.inputQuestion = '';
        }
      });
    },
    submitAnswer() {
      // validate answer with D2 API
      if (this.inputAnswer !== '') {
        axios.post(`https://api.d2pass.com/v1/secret_questions/${this.SQA.id}/validation`, {
          answer: this.inputAnswer,
          site_id: APP_CONFIG.site.d2pSiteId,
        }).then((response) => {
          if (response.status === 200 && response.data.result === true) {
            // successful SQA answer submission
            this.$modal.hide('ui-modal-sqa');

            // set cookie (checked in User postflight) so we don't hit the
            // API repeatedly in the same session
            Cookies.set('reupSQA', 1, { domain: APP_CONFIG.site.domain });
          }
        }).catch((error) => {
          if (error.response.status === 400 && error.response.data.result === false) {
            // unsuccessful SQA answer submission
            this.hasError = true;
          } else if (error.response.status === 433 && error.response.data.result === false && error.response.data.status === 'locked') {
            // multiple failures (10x attempts)
            this.SQA.lockoutMsg = error.response.data.messages[this.locale];

            // switch modal from submission form to lockout message
            this.showAnswerModal = false;
            this.showLockoutModal = true;

            // logout
            const userSvc = new User();
            userSvc.logout();
          }
        });
      }
    },
    beforeClose() {
      // stop listening to SQA messages
      EventBus.$off('SQA:triggered');
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    UID() {
      return this.$store.getters['user/UID'];
    },
  },
  watch: {
    inputQuestion(newVal, oldVal) {
      // if error message is visible, remove error message once the input question changes
      if (this.hasError && newVal !== oldVal) this.hasError = false;
    },
    inputAnswer(newVal, oldVal) {
      // if error message is visible, remove error message once the input answer changes
      if (this.hasError && newVal !== oldVal) this.hasError = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
