<i18n>
{
  "en": {
    "close_notification": "CLOSE"
  },
  "ja": {
    "close_notification": "閉じる"
  }
}
</i18n>

<template>
  <notifications group="maintenance" position="bottom center" class="vue-notification-bottom-center" width="84%" >
    <template slot="body" slot-scope="props">
      <div class="maintenance-template">
        <div class="maintenance-template-icon" @click="props.close">
          <svg class="dl-icon"><use xlink:href="#dl-icon-cancel"/></svg>
        </div>
        <div class="maintenance-template-content">
          <div class="maintenance-template-title">{{ props.item.title }}</div>
          <div class="maintenance-template-text" v-html="props.item.text"></div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */
/* vue/no-parsing-error: 0 */
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  mounted() {
    const bfAPI = new BifrostAPI();

    bfAPI.getMaintenance().then((result) => {
      if (Object.prototype.hasOwnProperty.call(result, 'subject') && Object.prototype.hasOwnProperty.call(result, 'body')) {
        this.$notify({
          group: 'maintenance',
          type: 'maintenance-info',
          title: result.subject[this.locale],
          text: result.body[this.locale],
          duration: -1,
        });
      }
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
.maintenance-template {
  text-align: left;
  font-size: 13px;
  margin: 0px;
  margin-bottom: 12px;
  &, & > div {
    box-sizing: border-box;
  }
  background: #ececec;
  border-top: 5px solid #A00956;

  .maintenance-template-icon {
    float: right;
    padding: 8px;
    cursor: pointer;
    opacity: 0.2;
    &:hover {
      opacity: 0.7;
    }
  }
  .maintenance-template-content {
    padding: 10px;
    .maintenance-template-title {
      letter-spacing: 1px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
</style>
