export default {
  // app config
  app: {
    version: process.env.VUE_APP_VERSION,
  },

  // site config
  site: {
    domain: 'pacopacomama.com',
    siteId: 2469,
    d2pSiteId: 20000917,
    title: {
      ja: ' | パコパコママ - 人妻・熟女専門アダルト動画',
      en: ' | pacopacomama.com - Japanese Porn Site Amateur Wife',
    },
    headerHeight: 129,
    engUrls: {
      'r.pacopacomama.com': 'https://r-en.pacopacomama.com/',
      'www.pacopacomama.com': 'https://en.pacopacomama.com/',
      'stg.pacopacomama.com': 'https://stg-en.pacopacomama.com/',
      'dev.pacopacomama.com': 'https://dev-en.pacopacomama.com/',
    },

    // start date of when english thumbnails were available (2017-02-01)
    enThumbsStartEpoch: 1485907200,

    // start date when both uncensored and censored sample videos were encoded (previously only
    // censored sample movies were available)
    uncensoredSampleStartEpoch: 1706745600000, // jan 31 2024 4pm in milliseconds

    // r3897 hide release dates for movies older than 6 months old
    releaseDateHiddenAge: 180, // days

    // app progressbar
    progressBarOptions: {
      color: '#94004B',
      failedColor: 'black',
      height: '3px',
    },
  },

  // purchase configs
  purchase: {
    // override if defined per hostname
    hostnames: {
      'stg.pacopacomama.com': { protocol: 'https', hostname: 'secure.d2pass.com.stg.d2p' },
      'stg-en.pacopacomama.com': { protocol: 'https', hostname: 'secure.d2pass.com.stg.d2p' },
    },
  },

  // google analytics constants
  analytics: {
    trackingIDs: {
      default: 'UA-41289115-1',
      hostnames: {
        // regex hostname match: tracking ID
        'dev.pacopacomama.com': 'UA-41289115-3',
        'dev-en.pacopacomama.com': 'UA-41289115-3',
        'stg.pacopacomama.com': 'UA-41289115-3',
        'stg-en.pacopacomama.com': 'UA-41289115-3',
        'www.pacopacomama.com': 'UA-41289115-1',
        'en.pacopacomama.com': 'UA-41289115-1',
        'r.pacopacomama.com': 'UA-41289115-1',
        'ren.pacopacomama.com': 'UA-41289115-1',
      },
    },
    customDimensions: {
      userType: 1,
      landerType: 2,
      connectionType: 3,
      language: 4,
      userBits: 5,
    },
    userTypeMap: {
      NonMember: { login: 'NonMember', logout: 'NonMember_LO' },
      Streaming: { login: 'Streaming', logout: 'Streaming_LO' },
      StreamingMember: { login: 'StreamingMember', logout: 'StreamingMember_LO' },
      Member: { login: 'Member', logout: 'Member_LO' },
      StreamingVIP: { login: 'StreamingVIP', logout: 'StreamingVIP_LO' },
      VIP: { login: 'VIP', logout: 'VIP_LO' },
      StreamingSVIP: { login: 'StreamingSuperVIP', logout: 'StreamingSuperVIP_LO' },
      SVIP: { login: 'SuperVIP', logout: 'SuperVIP_LO' },
      StreamingAnnual: { login: 'StreamingAnnual', logout: 'StreamingAnnual_LO' },
      AYCS: { login: 'AYCS', logout: 'AYCS_LO' },
      DTI: { login: 'DTI', logout: 'DTI_LO' },
    },
  },

  // banner admin
  badmin: {
    // prod > en prod + dev maps
    // these are now set in the webpack configs (build/webpack*.conf.js), so we don't expose our
    // personal virt names in the webpack bundle for production
    subdomainMap: {
      www: process.env.VUE_APP_BADMIN_MAP.split('||'),
    },
  },

  dml: {
    enabled: JSON.parse(process.env.VUE_APP_DML_ENABLED), // if false, use DDL
  },

  sqa: {
    enabled: JSON.parse(process.env.VUE_APP_SQA_ENABLED),
  },

  // locale
  i18n: {
    defaultLocale: 'ja',
    hostnames: {
      // regex hostname match: language
      'en.pacopacomama.com': 'en',
      'dev-en.pacopacomama.com': 'en',
      'stg-en.pacopacomama.com': 'en',
    },
  },

  // user config constants
  user: {
    // mapping for integer types to named user types
    typeMap: {
      0: 'NonMember',
      20: 'Streaming', // old streaming plan
      39: 'StreamingMember',
      40: 'Member',
      59: 'StreamingVIP',
      60: 'VIP',
      79: 'StreamingSVIP',
      80: 'SVIP',
      99: 'StreamingAnnual',
      200: 'AYCS',
    },

    propMap: {
      0: {
        type: 'NonMember',
        class: 'NonMember',
        tier: 'NonMember',
      },
      20: {
        type: 'Streaming',
        class: 'Streaming',
        tier: 'Member',
      },
      39: {
        type: 'StreamingMember',
        class: 'Streaming',
        tier: 'Member',
      },
      40: {
        type: 'Member',
        class: 'Download',
        tier: 'Member',
      },
      59: {
        type: 'StreamingVIP',
        class: 'Streaming',
        tier: 'VIP',
      },
      60: {
        type: 'VIP',
        class: 'Download',
        tier: 'VIP',
      },
      79: {
        type: 'StreamingSVIP',
        class: 'Streaming',
        tier: 'SVIP',
      },
      80: {
        type: 'SVIP',
        class: 'Download',
        tier: 'SVIP',
      },
      99: {
        type: 'StreamingAnnual',
        class: 'Streaming',
        tier: 'Annual',
      },
      200: {
        type: 'AYCS',
        class: 'Streaming',
        tier: 'AYCS',
      },
    },

    // get user flag bits (comments copy-pasta'd from backend source)
    flagBits: {
      Recurring: 1, // Recurring user
      Annual: 2, // Annual user, for display only. Permission is DownA
      Expire: 4, // Has expired tag
      DTI: 8, // DTI user
      StreamP: 16, // Partial streaming, all files but "No streaming"
      StreamF: 32, // Full streaming, ignore the "No streaming" flag
      DownN: 64, // Ability to download "Normal" movies
      DownV: 128, // Ability to download "VIP" movies
      DownS: 256, // Ability to download "SVIP" movies
      DownA: 512, // Ability to download "Annual" movies
      Fast: 1024, // User paid for faster downloads
      Limited: 2048, // User reached Daily Download Limit
      SQA: 4096, // User is SQA flagged
    },

    // hack-ish way of determining if a user is a trial user by comparing
    // start and end dates. trial is actually 2 hours long, but we add a fudge
    // factor in case join doesn't set the times exactly or near 2 hours long
    trialHoursThreshold: 24.5,
  },

  // movie download menu
  downloadMenu: {
    // use nginx video delivery? (uses Mercury movie key instead of DL's)
    useMercKey: false,

    // servers
    DML: {
      ja: {
        android: 'dl23',
        highspeed: 'dl21ac',
        default: 'dl21',
      },
      en: {
        android: 'dl23en',
        highspeed: 'dl21acen',
        default: 'dl21en',
      },
    },
    DDL: {
      android: 'dl13',
      highspeed: 'dl11ac',
      default: 'dl12',
    },

    // rewrite rules for filenames
    rewrite: {
      siteStr: 'paco',
      movieIdKey: 'MovieID',
    },

    // set to 'true' if the webserver sends the movie file with content-type "attachment"
    leftClickDownload: true,

    // list of UA browser names that support HTML5 "download attribute" (this config value is only
    // checked if the above config "leftClickDownload" is set to "false"
    html5Download: ['Chrome'],
  },

  // category constants (add to categories we get from backend-created json)
  categories: {
    50000: {
      category_id: 50000,
      name: {
        ja: 'VIP',
        en: 'VIP',
      },
    },
    50001: {
      category_id: 50001,
      name: {
        ja: '超VIP',
        en: 'SuperVIP',
      },
    },
    50002: {
      category_id: 50002,
      name: {
        ja: 'ストリーミング対応',
        en: 'Streaming',
      },
    },
    50003: {
      category_id: 50003,
      name: {
        ja: '年間動画',
        en: 'Annual',
      },
    },
    60000: {
      category_id: 60000,
      name: {
        ja: '1080p',
        en: '1080p',
      },
    },
    60001: {
      category_id: 60001,
      name: {
        ja: '60fps',
        en: '60fps',
      },
    },
    60002: {
      category_id: 60002,
      name: {
        ja: '720p',
        en: '720p',
      },
    },
  },

  // d2p toolbar
  d2pToolbar: {
    // master switch
    enabled: true,

    // script url
    url: process.env.VUE_APP_D2_HEADER_URL,

    // in pixels
    headerHeight: 46,

    // hostnames that will get the toolbar (not english sites)
    hostnames: {
      'r.pacopacomama.com': true,
      'www.pacopacomama.com': true,
      'stg.pacopacomama.com': true,
      'dev.pacopacomama.com': true,
    },

    // div id created by D2P Toolbar
    divID: 'd2p-groupheader',

    // iframe id created by D2P Toolbar
    iframeID: 'ga_toolbar',

    // style name. we do a string match for a unique string that we know only belongs to D2P Toolbar
    styleName: 'd2p-groupheader',

    // css can be customized by passing an optional path when initializing the toolbar
    // customCss: '/css/d2p_reset.css'
  },
};
